import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import Image from 'next/image'

interface IProps {
  mode: 'product-page' | 'product-tile' // TODO: on page, make the text larger (use higher level heading tags) and exclude the anchor, + add sezzle?
  title: string
  subtitle?: string
  price: number
  originalPrice?: number // TODO: Rename to originalPriceIfOnSale
  path: string
}

const ProductDetails: React.FC<IProps> = ({
  title,
  subtitle,
  price,
  originalPrice,
  path,
  mode,
}) => {
  const localePrice = useCallback((price: number) => {
    if (!price) {
      return '$0.00'
    }
    return price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }, [])

  const Contents = useMemo(
    () => (
      <>
        <h3
          className={
            'font-sans-condensed font-bold text-base leading-[0.84rem]'
          }
        >
          {title}
        </h3>
        <h4 className={'text-xs leading-[0.84rem] mt-0.5'}>{subtitle}</h4>
      </>
    ),
    [title, subtitle]
  )

  const ProductTilePriceSection = useMemo(
    () => (
      <div className={classNames('text-right', mode)}>
        <div
          className={
            'font-sans-condensed font-bold text-base text-primary leading-[0.94rem] price'
          }
        >
          {localePrice(price)}
        </div>
        {originalPrice && (
          <div className={'line-through text-xxs'}>
            {localePrice(originalPrice)}
          </div>
        )}
      </div>
    ),
    [localePrice, mode, originalPrice, price]
  )

  const ProductPagePriceSection = useMemo(
    () => (
      <div className={classNames('text-right', mode)}>
        <div
          className={
            'flex w-full text-right space-x-0.5 justify-end items-center'
          }
        >
          {originalPrice && (
            <div className={'line-through text-xxs'}>
              {localePrice(originalPrice)}
            </div>
          )}
          <div
            className={
              'font-sans-condensed font-bold text-base text-primary leading-[0.94rem] text-right price'
            }
          >
            {localePrice(price)}
          </div>
        </div>
      </div>
    ),
    [localePrice, mode, originalPrice, price]
  )

  return (
    <div className={'flex justify-between uppercase'}>
      {mode === 'product-page' ? (
        <div>{Contents}</div>
      ) : (
        <Link href={path} prefetch={false}>
          <a className={'max-w-[65.7%] hover:underline break-words'}>
            {Contents}
          </a>
        </Link>
      )}

      {mode === 'product-page'
        ? ProductPagePriceSection
        : ProductTilePriceSection}
    </div>
  )
}

export default ProductDetails
