import React from 'react'
import classNames from 'classnames'

import { BsBagPlus } from 'react-icons/bs'
import { AiOutlineLoading } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'

interface IProps {
  mode: 'add-to-cart' | 'unlock'
  loading?: boolean
  disabled?: boolean
  onClick?: () => void
}

const ActionButton: React.FC<IProps> = ({
  mode,
  loading,
  disabled,
  onClick,
}) => {
  /** TODO: This is the form trigger, factor this out */
  return (
    <button
      type={'button'}
      disabled={loading || disabled}
      onClick={onClick}
      className={classNames(
        'bg-white rounded-full w-12 h-12 text-2xl shadow-sm',
        {
          'opacity-50': !loading && disabled,
        }
      )}
    >
      {loading ? (
        <AiOutlineLoading className="mx-auto animate-spin" />
      ) : (
        <>
          {mode === 'add-to-cart' && <BsBagPlus className="mx-auto" />}
          {mode === 'unlock' && <RiLockPasswordLine className={'mx-auto'} />}
        </>
      )}
    </button>
  )
}

export default ActionButton
