import { useCallback } from 'react'

import handleKustomerInitialized from '@/lib/kustomer/handle-initialized'

const useKustomer = () => {
  const handleKustomerLoaded = useCallback(() => {
    const Kustomer = (window as any).Kustomer
    /* Kustomer.start(
      {
        brandId: '6181869d6293136591057e19',
        chatIconSize: '120px',
        zIndex: 9,
        preferredView: 'chat',
      },
      handleKustomerInitialized
    ) */
  }, [])
  return { handleKustomerLoaded }
}

export default useKustomer
