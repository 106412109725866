import Image from 'next/image'
import React from 'react'

interface IProps {
  image?: any
}

const CategoryListItem: React.FC<IProps> = ({ image }) => {
  return (
    <div className={'relative aspect-w-2 aspect-h-1'}>
      {image && ( // TODO: Expirement with urlOriginal and responsive image sixes
        <Image
          src={image.urlOriginal}
          alt={image.altText}
          unoptimized
          layout={'fill'}
        />
      )}
    </div>
  )
}

export default CategoryListItem
