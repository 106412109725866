import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { useAddItem } from '@/features/cart/hooks'
import ActionButton from './ActionButton'

interface IProps {
  productId: number
  defaultVariantId?: number
  onSuccess?: () => void
  onError?: () => void
}

const ActionButtonCartAddForm: React.FC<IProps> = ({
  productId,
  defaultVariantId,
  onSuccess,
  onError,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()
  const { addLineItem } = useAddItem({
    onSuccess,
    onError,
  })

  const onSubmit = useCallback(
    ({ product_id, quantity }: any) => {
      return addLineItem({
        product_id,
        variant_id: defaultVariantId,
        quantity,
      })
    },
    [addLineItem, defaultVariantId]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type={'hidden'}
        {...register('product_id', { value: productId })}
      />
      <input type={'hidden'} {...register('quantity', { value: 1 })} />
      <ActionButton
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        disabled={isSubmitting}
        mode={'add-to-cart'}
      />
    </form>
  )
}

export default ActionButtonCartAddForm
