import React from 'react'

interface IProps {
  children: React.ReactNode
}

const AlertBanner: React.FC<IProps> = ({ children }) => {
  return (
    <div
      className={
        // https://www.codegrepper.com/code-examples/css/css+how+to+make+width+of+container+fit+one+line+of+text
        // when text is one line, use text-[2.5vw], when it's two lines use text-[2vw]
        'bg-primary font-sans-condensed font-bold text-[2vw] sm:text-xxs uppercase text-white text-center p-1'
      }
    >
      {children}
    </div>
  )
}

export default AlertBanner
