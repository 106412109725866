import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/nextjs'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
      return Sentry.captureMessage(message)
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    Sentry.captureException(networkError)
  }
})

export default errorLink
