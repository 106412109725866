import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  DateTime: any
  GenericScalar: any
  JSONString: any
  PointScalar: any
  UUID: any
  Upload: any
}

export type AddPhoneNumberInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Enter your phone number */
  phoneNumber: Scalars['String']
}

/** Add a phone number to the user's account. */
export type AddPhoneNumberPayload = {
  __typename?: 'AddPhoneNumberPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  /** Enter your phone number */
  phoneNumber: Scalars['String']
}

export type AlphasLiveForeverLeadFormMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
}

export type AlphasLiveForeverLeadFormMutationPayload = {
  __typename?: 'AlphasLiveForeverLeadFormMutationPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  message?: Maybe<Scalars['String']>
  ok: Scalars['Boolean']
  phone?: Maybe<Scalars['String']>
}

export type AnalyticsEventCounts = {
  __typename?: 'AnalyticsEventCounts'
  addToCart?: Maybe<Scalars['Int']>
  placeOrder?: Maybe<Scalars['Int']>
  startCheckout?: Maybe<Scalars['Int']>
}

export type AppleFullName = {
  familyName?: InputMaybe<Scalars['String']>
  givenName?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  namePrefix?: InputMaybe<Scalars['String']>
  nameSuffix?: InputMaybe<Scalars['String']>
  nickname?: InputMaybe<Scalars['String']>
}

export type AppleSignIn = {
  __typename?: 'AppleSignIn'
  ok?: Maybe<Scalars['Boolean']>
  token?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type AppleSignInMutationInput = {
  authorizationCode: Scalars['String']
  fullName?: InputMaybe<AppleFullName>
  state?: InputMaybe<Scalars['String']>
}

export type AprilWarehouseLeadFormMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
}

export type AprilWarehouseLeadFormMutationPayload = {
  __typename?: 'AprilWarehouseLeadFormMutationPayload'
  clientMutationId?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  ok: Scalars['Boolean']
  phone?: Maybe<Scalars['String']>
}

export type Asset = Node & {
  __typename?: 'Asset'
  aspectRatio?: Maybe<Scalars['String']>
  chatMessages: AssetChatMessageConnection
  createdAt: Scalars['DateTime']
  duration?: Maybe<Scalars['Int']>
  errors?: Maybe<MuxAssetErrors>
  /** The ID of the object */
  id: Scalars['ID']
  isLive: Scalars['Boolean']
  liveStream: UserLiveStream
  muxId: Scalars['String']
  pinnedMessage?: Maybe<PinnedMessage>
  playbackIds?: Maybe<Array<Maybe<MuxAssetPlaybackId>>>
  status: LivestreamsAssetStatusChoices
  test: Scalars['Boolean']
}

export type AssetChatMessagesArgs = {
  after?: InputMaybe<Scalars['String']>
  asset?: InputMaybe<Scalars['ID']>
  asset_LiveStream?: InputMaybe<Scalars['ID']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type AssetChatMessage = Node & {
  __typename?: 'AssetChatMessage'
  asset: Asset
  avatarUrl?: Maybe<Scalars['String']>
  /** The ID of the object */
  id: Scalars['ID']
  message: Scalars['String']
  timestamp: Scalars['DateTime']
  user?: Maybe<User>
  username: Scalars['String']
}

export type AssetChatMessageConnection = {
  __typename?: 'AssetChatMessageConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetChatMessageEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `AssetChatMessage` and its cursor. */
export type AssetChatMessageEdge = {
  __typename?: 'AssetChatMessageEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<AssetChatMessage>
}

export type AssetConnection = {
  __typename?: 'AssetConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `Asset` and its cursor. */
export type AssetEdge = {
  __typename?: 'AssetEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<Asset>
}

export type BigCommerceOrderNumberEmailAuthInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  orderNumber: Scalars['String']
}

export type BigCommerceOrderNumberEmailAuthPayload = {
  __typename?: 'BigCommerceOrderNumberEmailAuthPayload'
  clientMutationId?: Maybe<Scalars['String']>
  email: Scalars['String']
  errors?: Maybe<Array<Maybe<ErrorType>>>
  orderNumber: Scalars['String']
  token?: Maybe<Scalars['String']>
}

export type BigCommerceSignInInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  password: Scalars['String']
}

export type BigCommerceSignInPayload = {
  __typename?: 'BigCommerceSignInPayload'
  clientMutationId?: Maybe<Scalars['String']>
  email: Scalars['String']
  errors?: Maybe<Array<Maybe<ErrorType>>>
  password: Scalars['String']
  token?: Maybe<Scalars['String']>
}

export type CompleteOtpRegistrationInput = {
  avatar?: InputMaybe<Scalars['Upload']>
  clientMutationId?: InputMaybe<Scalars['String']>
  token: Scalars['String']
  username: Scalars['String']
}

/** Complete OTP registration */
export type CompleteOtpRegistrationPayload = {
  __typename?: 'CompleteOTPRegistrationPayload'
  authToken?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['Upload']>
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  payload?: Maybe<Scalars['JSONString']>
  refreshExpiresIn?: Maybe<Scalars['Int']>
  refreshToken?: Maybe<Scalars['String']>
  token: Scalars['String']
  user?: Maybe<User>
  username: Scalars['String']
}

export type CreateFileInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  fileName: Scalars['String']
  /** The ID of the entity object file to be created. */
  id?: InputMaybe<Scalars['ID']>
  purpose: FilePurpose
}

/** Create a file instance and return a presigned post for it  */
export type CreateFilePayload = {
  __typename?: 'CreateFilePayload'
  clientMutationId?: Maybe<Scalars['String']>
  file?: Maybe<File>
  ok: Scalars['Boolean']
  presignedPost?: Maybe<StandardPresignedPost>
}

export type CreateStoryFileInput = {
  banner?: InputMaybe<StoryFileBannerInput>
  clientMutationId?: InputMaybe<Scalars['String']>
  interactiveAreas?: InputMaybe<
    Array<InputMaybe<StoryFileInteractiveAreaInput>>
  >
  originalFileName: Scalars['String']
}

/** Create a story file instance and return a presigned post for it */
export type CreateStoryFilePayload = {
  __typename?: 'CreateStoryFilePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<ErrorType>
  presignedPost?: Maybe<StandardPresignedPost>
  storyFile?: Maybe<StoryFile>
}

/** Create a token that can be used to authenticate with the websocket server */
export type CreativeWebsocketToken = {
  __typename?: 'CreativeWebsocketToken'
  error?: Maybe<Scalars['String']>
  ok: Scalars['Boolean']
  token?: Maybe<Scalars['String']>
}

export type DeleteJsonWebTokenCookieInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
}

export type DeleteJsonWebTokenCookiePayload = {
  __typename?: 'DeleteJSONWebTokenCookiePayload'
  clientMutationId?: Maybe<Scalars['String']>
  deleted: Scalars['Boolean']
}

export type DeleteLiveStreamAssetMutation = {
  __typename?: 'DeleteLiveStreamAssetMutation'
  deletedAssetId?: Maybe<Scalars['ID']>
  message?: Maybe<Scalars['String']>
  ok?: Maybe<Scalars['Boolean']>
}

export type DeleteMyAccount = {
  __typename?: 'DeleteMyAccount'
  ok?: Maybe<Scalars['Boolean']>
}

export type DeleteRefreshTokenCookieInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
}

export type DeleteRefreshTokenCookiePayload = {
  __typename?: 'DeleteRefreshTokenCookiePayload'
  clientMutationId?: Maybe<Scalars['String']>
  deleted: Scalars['Boolean']
}

export type DeleteRsvpMutation = {
  __typename?: 'DeleteRsvpMutation'
  deletedEventRsvpId?: Maybe<Scalars['ID']>
  message?: Maybe<Scalars['String']>
  ok: Scalars['Boolean']
}

export type DeleteStoryFile = {
  __typename?: 'DeleteStoryFile'
  deletedObjectId?: Maybe<Scalars['ID']>
  deletedObjectType?: Maybe<Scalars['String']>
  ok?: Maybe<Scalars['Boolean']>
}

export type ErrorType = {
  __typename?: 'ErrorType'
  field: Scalars['String']
  messages: Array<Scalars['String']>
}

export type Event = Node & {
  __typename?: 'Event'
  endTime?: Maybe<Scalars['DateTime']>
  /** The ID of the object */
  id: Scalars['ID']
  isFuture: Scalars['Boolean']
  isHappeningNow: Scalars['Boolean']
  isPast: Scalars['Boolean']
  rsvps: EventRsvpConnection
  startTime: Scalars['DateTime']
  title?: Maybe<Scalars['String']>
  type: EventType
  uuid: Scalars['UUID']
  venue: EventVenue
}

export type EventRsvpsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  event?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type EventConnection = {
  __typename?: 'EventConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EventEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `Event` and its cursor. */
export type EventEdge = {
  __typename?: 'EventEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<Event>
}

export type EventRsvp = Node & {
  __typename?: 'EventRsvp'
  additionalFemaleGuests: Scalars['Int']
  additionalMaleGuests: Scalars['Int']
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  event: Event
  fullName: Scalars['String']
  gender: EventRsvpGender
  /** The ID of the object */
  id: Scalars['ID']
  instagramHandle?: Maybe<Scalars['String']>
  isDeleted: Scalars['Boolean']
  phoneNumber?: Maybe<Scalars['String']>
  state: EventRsvpState
  totalAcceptedGuests?: Maybe<Scalars['Int']>
  totalPendingGuests?: Maybe<Scalars['Int']>
}

export type EventRsvpConnection = {
  __typename?: 'EventRsvpConnection'
  acceptedGuests?: Maybe<GuestTotals>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EventRsvpEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  pendingGuests?: Maybe<GuestTotals>
}

/** A Relay edge containing a `EventRsvp` and its cursor. */
export type EventRsvpEdge = {
  __typename?: 'EventRsvpEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<EventRsvp>
  totalGuests?: Maybe<Scalars['Int']>
}

export enum EventRsvpGender {
  Female = 'FEMALE',
  Male = 'MALE',
}

export enum EventRsvpState {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export enum EventType {
  Party = 'Party',
}

export type EventVenue = Node & {
  __typename?: 'EventVenue'
  address?: Maybe<Scalars['String']>
  city: Scalars['String']
  events: EventConnection
  /** The ID of the object */
  id: Scalars['ID']
  isSecret: Scalars['Boolean']
  location?: Maybe<Scalars['PointScalar']>
  name: Scalars['String']
  state: Scalars['String']
  zipCode?: Maybe<Scalars['String']>
}

export type EventVenueEventsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  endTime?: InputMaybe<Scalars['DateTime']>
  endTime_Gt?: InputMaybe<Scalars['DateTime']>
  endTime_Gte?: InputMaybe<Scalars['DateTime']>
  endTime_Lt?: InputMaybe<Scalars['DateTime']>
  endTime_Lte?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  startTime?: InputMaybe<Scalars['DateTime']>
  startTime_Gt?: InputMaybe<Scalars['DateTime']>
  startTime_Gte?: InputMaybe<Scalars['DateTime']>
  startTime_Lt?: InputMaybe<Scalars['DateTime']>
  startTime_Lte?: InputMaybe<Scalars['DateTime']>
  venue?: InputMaybe<Scalars['ID']>
}

export type EventVenueConnection = {
  __typename?: 'EventVenueConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EventVenueEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `EventVenue` and its cursor. */
export type EventVenueEdge = {
  __typename?: 'EventVenueEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<EventVenue>
}

/** File interface */
export type File = {
  createdAt: Scalars['DateTime']
  creator?: Maybe<User>
  file?: Maybe<Scalars['String']>
  fileType?: Maybe<Scalars['String']>
  isPreviewable?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<FileMetadata>
  originalFileName?: Maybe<Scalars['String']>
  thumbnailUrl?: Maybe<Scalars['String']>
  updated: Scalars['DateTime']
}

/** File interface */
export type FileThumbnailUrlArgs = {
  height?: InputMaybe<Scalars['Int']>
  width?: InputMaybe<Scalars['Int']>
}

export type FileMetadata = {
  __typename?: 'FileMetadata'
  angle?: Maybe<Scalars['Float']>
  audio?: Maybe<Scalars['Boolean']>
  bitRate?: Maybe<Scalars['Int']>
  blurHash?: Maybe<Scalars['String']>
  displayAspectRatio?: Maybe<Scalars['Float']>
  duration?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  video?: Maybe<Scalars['Boolean']>
  width?: Maybe<Scalars['Int']>
}

export enum FilePurpose {
  KirillsHousePartyStory = 'KIRILLS_HOUSE_PARTY_STORY',
}

export type GuestTotals = {
  __typename?: 'GuestTotals'
  females?: Maybe<Scalars['Int']>
  males?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
}

export type HitstepsDailyStatisticsData = {
  __typename?: 'HitstepsDailyStatisticsData'
  bouncerate?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  day?: Maybe<Scalars['String']>
  newVisits?: Maybe<Scalars['String']>
  pageviews?: Maybe<Scalars['String']>
  refDirect?: Maybe<Scalars['String']>
  refFromSearch?: Maybe<Scalars['String']>
  refFromSites?: Maybe<Scalars['String']>
  refSocialmedia?: Maybe<Scalars['String']>
  visits?: Maybe<Scalars['String']>
}

export type HitstepsRealtimeData = {
  __typename?: 'HitstepsRealtimeData'
  online?: Maybe<Scalars['Int']>
  onlineActive?: Maybe<Scalars['Int']>
  onlineDesktop?: Maybe<Scalars['Int']>
  onlineIdle?: Maybe<Scalars['Int']>
  onlineMobile?: Maybe<Scalars['Int']>
  totalPageviews?: Maybe<Scalars['Int']>
  totalVisitors?: Maybe<Scalars['Int']>
  unread?: Maybe<Scalars['Int']>
  unreadMe?: Maybe<Scalars['Int']>
}

export type ImageFile = LegacyFile & {
  __typename?: 'ImageFile'
  filename?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  mimeType: Scalars['String']
  size?: Maybe<Scalars['Int']>
  url: Scalars['String']
  width?: Maybe<Scalars['Int']>
}

export type InfluencerProfile = {
  __typename?: 'InfluencerProfile'
  address1?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  birthDate: Scalars['Date']
  braSize?: Maybe<InfluencersInfluencerProfileBraSizeChoices>
  city: Scalars['String']
  country: InfluencersInfluencerProfileCountryChoices
  email: Scalars['String']
  favoriteColor: Scalars['String']
  gender: InfluencersInfluencerProfileGenderChoices
  hoodieSize: InfluencersInfluencerProfileHoodieSizeChoices
  id: Scalars['ID']
  instagram?: Maybe<Scalars['String']>
  isSober: Scalars['Boolean']
  name: Scalars['String']
  occupation?: Maybe<Scalars['String']>
  onlyfans?: Maybe<Scalars['String']>
  phoneNumber: Scalars['String']
  shoeSize: Scalars['Float']
  snapchat?: Maybe<Scalars['String']>
  stageName?: Maybe<Scalars['String']>
  state: InfluencersInfluencerProfileStateChoices
  submitted: Scalars['DateTime']
  sweatpantsSize: InfluencersInfluencerProfileSweatpantsSizeChoices
  teeSize: InfluencersInfluencerProfileTeeSizeChoices
  tiktok?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  undiesSize: InfluencersInfluencerProfileUndiesSizeChoices
  vices: Array<Vice>
  zipCode: Scalars['String']
}

export type InfluencerProfileInput = {
  address1?: InputMaybe<Scalars['String']>
  address2?: InputMaybe<Scalars['String']>
  birthDate: Scalars['Date']
  braSize?: InputMaybe<Scalars['String']>
  city: Scalars['String']
  clientMutationId?: InputMaybe<Scalars['String']>
  country: Scalars['String']
  email: Scalars['String']
  favoriteColor: Scalars['String']
  gender: Scalars['String']
  hoodieSize: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
  instagram?: InputMaybe<Scalars['String']>
  isSober?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  occupation?: InputMaybe<Scalars['String']>
  onlyfans?: InputMaybe<Scalars['String']>
  phoneNumber: Scalars['String']
  shoeSize: Scalars['Float']
  snapchat?: InputMaybe<Scalars['String']>
  stageName?: InputMaybe<Scalars['String']>
  state: Scalars['String']
  sweatpantsSize: Scalars['String']
  teeSize: Scalars['String']
  tiktok?: InputMaybe<Scalars['String']>
  twitter?: InputMaybe<Scalars['String']>
  undiesSize: Scalars['String']
  vices?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  zipCode: Scalars['String']
}

export type InfluencerProfilePayload = {
  __typename?: 'InfluencerProfilePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<ErrorType>
  influencerProfile?: Maybe<InfluencerProfile>
}

/** An enumeration. */
export enum InfluencersInfluencerProfileBraSizeChoices {
  /** L */
  L = 'L',
  /** M */
  M = 'M',
  /** S */
  S = 'S',
  /** XL */
  Xl = 'XL',
  /** XS */
  Xs = 'XS',
}

/** An enumeration. */
export enum InfluencersInfluencerProfileCountryChoices {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syria */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Türkiye */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

/** An enumeration. */
export enum InfluencersInfluencerProfileGenderChoices {
  /** Female */
  F = 'F',
  /** Male */
  M = 'M',
  /** Trans */
  T = 'T',
}

/** An enumeration. */
export enum InfluencersInfluencerProfileHoodieSizeChoices {
  /** L */
  L = 'L',
  /** M */
  M = 'M',
  /** S */
  S = 'S',
  /** XL */
  Xl = 'XL',
  /** XXL */
  Xxl = 'XXL',
  /** XXXL */
  Xxxl = 'XXXL',
}

/** An enumeration. */
export enum InfluencersInfluencerProfileStateChoices {
  /** Armed Forces Americas */
  Aa = 'AA',
  /** Armed Forces Europe */
  Ae = 'AE',
  /** Alaska */
  Ak = 'AK',
  /** Alabama */
  Al = 'AL',
  /** Armed Forces Pacific */
  Ap = 'AP',
  /** Arkansas */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Arizona */
  Az = 'AZ',
  /** California */
  Ca = 'CA',
  /** Colorado */
  Co = 'CO',
  /** Connecticut */
  Ct = 'CT',
  /** District of Columbia */
  Dc = 'DC',
  /** Delaware */
  De = 'DE',
  /** Florida */
  Fl = 'FL',
  /** Georgia */
  Ga = 'GA',
  /** Guam */
  Gu = 'GU',
  /** Hawaii */
  Hi = 'HI',
  /** Iowa */
  Ia = 'IA',
  /** Idaho */
  Id = 'ID',
  /** Illinois */
  Il = 'IL',
  /** Indiana */
  In = 'IN',
  /** Kansas */
  Ks = 'KS',
  /** Kentucky */
  Ky = 'KY',
  /** Louisiana */
  La = 'LA',
  /** Massachusetts */
  Ma = 'MA',
  /** Maryland */
  Md = 'MD',
  /** Maine */
  Me = 'ME',
  /** Michigan */
  Mi = 'MI',
  /** Minnesota */
  Mn = 'MN',
  /** Missouri */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Mississippi */
  Ms = 'MS',
  /** Montana */
  Mt = 'MT',
  /** North Carolina */
  Nc = 'NC',
  /** North Dakota */
  Nd = 'ND',
  /** Nebraska */
  Ne = 'NE',
  /** New Hampshire */
  Nh = 'NH',
  /** New Jersey */
  Nj = 'NJ',
  /** New Mexico */
  Nm = 'NM',
  /** Nevada */
  Nv = 'NV',
  /** New York */
  Ny = 'NY',
  /** Ohio */
  Oh = 'OH',
  /** Oklahoma */
  Ok = 'OK',
  /** Oregon */
  Or = 'OR',
  /** Pennsylvania */
  Pa = 'PA',
  /** Puerto Rico */
  Pr = 'PR',
  /** Rhode Island */
  Ri = 'RI',
  /** South Carolina */
  Sc = 'SC',
  /** South Dakota */
  Sd = 'SD',
  /** Tennessee */
  Tn = 'TN',
  /** Texas */
  Tx = 'TX',
  /** Utah */
  Ut = 'UT',
  /** Virginia */
  Va = 'VA',
  /** Virgin Islands */
  Vi = 'VI',
  /** Vermont */
  Vt = 'VT',
  /** Washington */
  Wa = 'WA',
  /** Wisconsin */
  Wi = 'WI',
  /** West Virginia */
  Wv = 'WV',
  /** Wyoming */
  Wy = 'WY',
}

/** An enumeration. */
export enum InfluencersInfluencerProfileSweatpantsSizeChoices {
  /** L */
  L = 'L',
  /** M */
  M = 'M',
  /** S */
  S = 'S',
  /** XL */
  Xl = 'XL',
  /** XXL */
  Xxl = 'XXL',
  /** XXXL */
  Xxxl = 'XXXL',
}

/** An enumeration. */
export enum InfluencersInfluencerProfileTeeSizeChoices {
  /** L */
  L = 'L',
  /** M */
  M = 'M',
  /** S */
  S = 'S',
  /** XL */
  Xl = 'XL',
  /** XXL */
  Xxl = 'XXL',
  /** XXXL */
  Xxxl = 'XXXL',
}

/** An enumeration. */
export enum InfluencersInfluencerProfileUndiesSizeChoices {
  /** L */
  L = 'L',
  /** M */
  M = 'M',
  /** S */
  S = 'S',
  /** XL */
  Xl = 'XL',
  /** XS */
  Xs = 'XS',
}

export type LegacyFile = {
  filename?: Maybe<Scalars['String']>
  mimeType: Scalars['String']
  size?: Maybe<Scalars['Int']>
  url: Scalars['String']
}

/** The live story for the current day  */
export type LegacyLiveStory = {
  __typename?: 'LegacyLiveStory'
  items: Array<Maybe<LegacyStoryItem>>
  preview?: Maybe<LegacyStoryItem>
}

export type LegacyStoryItem = ImageFile | VideoFile

/** The live story for the current day  */
export type LiveStory = {
  __typename?: 'LiveStory'
  freePreviewCount: Scalars['Int']
  isFallback: Scalars['Boolean']
  items: Array<Maybe<LiveStoryItem>>
  preview?: Maybe<LiveStoryItem>
}

export type LiveStoryFallbackItem = LiveStoryItem & {
  __typename?: 'LiveStoryFallbackItem'
  file?: Maybe<Scalars['String']>
  fileType?: Maybe<Scalars['String']>
  thumbnailUrl?: Maybe<Scalars['String']>
  uuid: Scalars['UUID']
}

export type LiveStoryFallbackItemThumbnailUrlArgs = {
  height?: InputMaybe<Scalars['Int']>
  width?: InputMaybe<Scalars['Int']>
}

/** An item in the live story for the current day  */
export type LiveStoryItem = {
  file?: Maybe<Scalars['String']>
  fileType?: Maybe<Scalars['String']>
  thumbnailUrl?: Maybe<Scalars['String']>
}

/** An item in the live story for the current day  */
export type LiveStoryItemThumbnailUrlArgs = {
  height?: InputMaybe<Scalars['Int']>
  width?: InputMaybe<Scalars['Int']>
}

export type Livestream = {
  __typename?: 'Livestream'
  event?: Maybe<LivestreamEvent>
  livestream?: Maybe<UserLiveStream>
}

export type LivestreamEvent =
  | AssetChatMessage
  | LivestreamHostEvent
  | LivestreamMessagePinEvent
  | LivestreamVideoEvent

export type LivestreamHostEvent = {
  __typename?: 'LivestreamHostEvent'
  type: LivestreamHostEventType
}

export enum LivestreamHostEventType {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Reconnected = 'RECONNECTED',
}

export type LivestreamMessagePinEvent = {
  __typename?: 'LivestreamMessagePinEvent'
  pinnedMessage?: Maybe<PinnedMessage>
  type: LivestreamMessagePinEventType
}

export enum LivestreamMessagePinEventType {
  Pinned = 'PINNED',
  Unpinned = 'UNPINNED',
}

export type LivestreamVideoEvent = {
  __typename?: 'LivestreamVideoEvent'
  type: LivestreamVideoEventType
}

export enum LivestreamVideoEventType {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Idle = 'IDLE',
  Recording = 'RECORDING',
}

/** An enumeration. */
export enum LivestreamsAssetStatusChoices {
  /** Errored */
  Errored = 'ERRORED',
  /** Preparing */
  Preparing = 'PREPARING',
  /** Ready */
  Ready = 'READY',
}

/** An enumeration. */
export enum LivestreamsMutedUserMuteTypeChoices {
  /** Duration */
  Duration = 'DURATION',
  /** Permanent */
  Permanent = 'PERMANENT',
  /** Until End Of Stream */
  UntilEndOfStream = 'UNTIL_END_OF_STREAM',
}

/** An enumeration. */
export enum LivestreamsUserLiveStreamStatusChoices {
  /** Active */
  Active = 'ACTIVE',
  /** Disabled */
  Disabled = 'DISABLED',
  /** Idle */
  Idle = 'IDLE',
}

export type LoginInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  login: Scalars['String']
  password: Scalars['String']
  remember?: InputMaybe<Scalars['Boolean']>
}

/** Login the user */
export type LoginPayload = {
  __typename?: 'LoginPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  login: Scalars['String']
  password: Scalars['String']
  payload?: Maybe<Scalars['JSONString']>
  refreshExpiresIn?: Maybe<Scalars['Int']>
  refreshToken?: Maybe<Scalars['String']>
  remember?: Maybe<Scalars['Boolean']>
  token?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Add a phone number to the user's account. */
  addPhoneNumber?: Maybe<AddPhoneNumberPayload>
  alphasLiveForeverLead?: Maybe<AlphasLiveForeverLeadFormMutationPayload>
  appleSignIn?: Maybe<AppleSignIn>
  aprilWarehouseLead?: Maybe<AprilWarehouseLeadFormMutationPayload>
  bigcommerceSignIn?: Maybe<BigCommerceSignInPayload>
  /** Complete OTP registration */
  completeOtpRegistration?: Maybe<CompleteOtpRegistrationPayload>
  /** Create a file instance and return a presigned post for it  */
  createFile?: Maybe<CreateFilePayload>
  /** Create a story file instance and return a presigned post for it */
  createStoryFile?: Maybe<CreateStoryFilePayload>
  /** Create a token that can be used to authenticate with the websocket server */
  createWebsocketToken?: Maybe<CreativeWebsocketToken>
  deleteLiveStreamAsset?: Maybe<DeleteLiveStreamAssetMutation>
  deleteMyAccount?: Maybe<DeleteMyAccount>
  deleteRefreshTokenCookie?: Maybe<DeleteRefreshTokenCookiePayload>
  deleteRsvp?: Maybe<DeleteRsvpMutation>
  deleteStoryFile?: Maybe<DeleteStoryFile>
  deleteTokenCookie?: Maybe<DeleteJsonWebTokenCookiePayload>
  influencerProfile?: Maybe<InfluencerProfilePayload>
  /** Login the user */
  login?: Maybe<LoginPayload>
  muteUser?: Maybe<MuteUserPayload>
  nextEventRsvp?: Maybe<NextEventRsvpFormMutationPayload>
  obtainChatToken?: Maybe<ObtainChatTokenMutation>
  pinLivestreamMessage?: Maybe<PinLivestreamMessagePayload>
  recordStorefrontEvent?: Maybe<RecordStorefrontEvent>
  refreshToken?: Maybe<RefreshPayload>
  /** Removes a mute from a user's livestream  */
  removeMute?: Maybe<RemoveMute>
  removePhoneNumber?: Maybe<RemovePhoneNumber>
  /** Send a code to the user's phone number for login */
  requestPhoneLoginOtp?: Maybe<RequestPhoneLoginOtpPayload>
  /** Request an OTP code for a phone number, to be used to login or signup later */
  requestPhoneOtp?: Maybe<RequestPhoneOtpPayload>
  reviewRsvp?: Maybe<ReviewRsvpMutation>
  revokeToken?: Maybe<RevokePayload>
  sendChatMessage?: Maybe<SendChatMessage>
  signalLiveStreamComplete?: Maybe<SignalLiveStreamCompleteMutation>
  /** Sign up for a new account */
  signup?: Maybe<SignupPayload>
  storyBigCommerceOrderEmailAuth?: Maybe<BigCommerceOrderNumberEmailAuthPayload>
  storyConfig?: Maybe<StoryConfigPayload>
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebTokenPayload>
  unpinLivestreamMessage?: Maybe<UnpinLivestreamMessage>
  updateUserAvatarAndUsername?: Maybe<UpdateUserAvatarAndUsername>
  /** User profile mutation  */
  userProfile?: Maybe<UserProfilePayload>
  /** Verify a phone number code sent via SMS for logging in */
  verifyPhoneLoginOtp?: Maybe<VerifyPhoneLoginOtpPayload>
  /** Verify a phone number with a code sent via SMS */
  verifyPhoneNumber?: Maybe<VerifyPhoneNumberPayload>
  /** Verify an OTP code for a phone number, logs in if the user exists, otherwise sends sign up token */
  verifyPhoneOtp?: Maybe<VerifyPhoneOtpPayload>
  verifyToken?: Maybe<VerifyPayload>
}

export type MutationAddPhoneNumberArgs = {
  input: AddPhoneNumberInput
}

export type MutationAlphasLiveForeverLeadArgs = {
  input: AlphasLiveForeverLeadFormMutationInput
}

export type MutationAppleSignInArgs = {
  input: AppleSignInMutationInput
}

export type MutationAprilWarehouseLeadArgs = {
  input: AprilWarehouseLeadFormMutationInput
}

export type MutationBigcommerceSignInArgs = {
  input: BigCommerceSignInInput
}

export type MutationCompleteOtpRegistrationArgs = {
  input: CompleteOtpRegistrationInput
}

export type MutationCreateFileArgs = {
  input: CreateFileInput
}

export type MutationCreateStoryFileArgs = {
  input: CreateStoryFileInput
}

export type MutationCreateWebsocketTokenArgs = {
  purpose: WebsocketTokenPurpose
}

export type MutationDeleteLiveStreamAssetArgs = {
  id: Scalars['ID']
}

export type MutationDeleteRefreshTokenCookieArgs = {
  input: DeleteRefreshTokenCookieInput
}

export type MutationDeleteRsvpArgs = {
  id: Scalars['ID']
}

export type MutationDeleteStoryFileArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTokenCookieArgs = {
  input: DeleteJsonWebTokenCookieInput
}

export type MutationInfluencerProfileArgs = {
  input: InfluencerProfileInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationMuteUserArgs = {
  input: MuteUserInput
}

export type MutationNextEventRsvpArgs = {
  input: NextEventRsvpFormMutationInput
}

export type MutationPinLivestreamMessageArgs = {
  input: PinLivestreamMessageInput
}

export type MutationRecordStorefrontEventArgs = {
  event: StorefrontEventType
}

export type MutationRefreshTokenArgs = {
  input: RefreshInput
}

export type MutationRemoveMuteArgs = {
  id: Scalars['ID']
}

export type MutationRemovePhoneNumberArgs = {
  id: Scalars['ID']
}

export type MutationRequestPhoneLoginOtpArgs = {
  input: RequestPhoneLoginOtpInput
}

export type MutationRequestPhoneOtpArgs = {
  input: RequestPhoneOtpInput
}

export type MutationReviewRsvpArgs = {
  action: ReviewRsvpAction
  id: Scalars['ID']
}

export type MutationRevokeTokenArgs = {
  input: RevokeInput
}

export type MutationSendChatMessageArgs = {
  livestreamId: Scalars['ID']
  message: Scalars['String']
}

export type MutationSignupArgs = {
  input: SignupInput
}

export type MutationStoryBigCommerceOrderEmailAuthArgs = {
  input: BigCommerceOrderNumberEmailAuthInput
}

export type MutationStoryConfigArgs = {
  input: StoryConfigInput
}

export type MutationTokenAuthArgs = {
  input: ObtainJsonWebTokenInput
}

export type MutationUpdateUserAvatarAndUsernameArgs = {
  input: UpdateUserAvatarAndUsernameInput
}

export type MutationUserProfileArgs = {
  input: UserProfileInput
}

export type MutationVerifyPhoneLoginOtpArgs = {
  input: VerifyPhoneLoginOtpInput
}

export type MutationVerifyPhoneNumberArgs = {
  input: VerifyPhoneNumberInput
}

export type MutationVerifyPhoneOtpArgs = {
  input: VerifyPhoneOtpInput
}

export type MutationVerifyTokenArgs = {
  input: VerifyInput
}

export enum MuteType {
  Duration = 'DURATION',
  Permanent = 'PERMANENT',
  UntilEndOfStream = 'UNTIL_END_OF_STREAM',
}

export type MuteUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  expiresAt?: InputMaybe<Scalars['DateTime']>
  message?: InputMaybe<Scalars['String']>
  muteType: MuteType
  user: Scalars['ID']
}

export type MuteUserPayload = {
  __typename?: 'MuteUserPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<ErrorType>
  mutedUser?: Maybe<MutedUser>
}

export type MutedUser = Node & {
  __typename?: 'MutedUser'
  createdAt: Scalars['DateTime']
  expiresAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object */
  id: Scalars['ID']
  livestream: UserLiveStream
  message?: Maybe<Scalars['String']>
  muteType: LivestreamsMutedUserMuteTypeChoices
  user: User
}

export type MutedUserConnection = {
  __typename?: 'MutedUserConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MutedUserEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MutedUser` and its cursor. */
export type MutedUserEdge = {
  __typename?: 'MutedUserEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<MutedUser>
}

export type MuxAsset = Node & {
  __typename?: 'MuxAsset'
  aspectRatio?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  duration?: Maybe<Scalars['Float']>
  errors?: Maybe<MuxAssetErrors>
  /** The ID of the object */
  id: Scalars['ID']
  isLive?: Maybe<Scalars['Boolean']>
  liveStreamId?: Maybe<Scalars['String']>
  playbackIds?: Maybe<Array<Maybe<MuxAssetPlaybackId>>>
  status: Scalars['String']
  test?: Maybe<Scalars['Boolean']>
}

export type MuxAssetErrors = {
  __typename?: 'MuxAssetErrors'
  messages?: Maybe<Array<Maybe<Scalars['String']>>>
  type?: Maybe<Scalars['String']>
}

export type MuxAssetPlaybackId = {
  __typename?: 'MuxAssetPlaybackId'
  hlsUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  policy?: Maybe<Scalars['String']>
  thumbnailUrl?: Maybe<Scalars['String']>
}

export type NextEventRsvpFormMutationInput = {
  additionalFemaleGuests: Scalars['Int']
  additionalMaleGuests: Scalars['Int']
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  fullName: Scalars['String']
  gender: Scalars['String']
  instagramHandle: Scalars['String']
  phoneNumber: Scalars['String']
}

export type NextEventRsvpFormMutationPayload = {
  __typename?: 'NextEventRsvpFormMutationPayload'
  additionalFemaleGuests: Scalars['Int']
  additionalMaleGuests: Scalars['Int']
  clientMutationId?: Maybe<Scalars['String']>
  email: Scalars['String']
  errors?: Maybe<Array<Maybe<ErrorType>>>
  eventRsvp?: Maybe<EventRsvp>
  fullName: Scalars['String']
  gender: Scalars['String']
  instagramHandle: Scalars['String']
  phoneNumber: Scalars['String']
}

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']
}

export type ObtainChatTokenMutation = {
  __typename?: 'ObtainChatTokenMutation'
  ok?: Maybe<Scalars['Boolean']>
  token?: Maybe<Scalars['String']>
}

export type ObtainJsonWebTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  password: Scalars['String']
  username: Scalars['String']
}

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebTokenPayload = {
  __typename?: 'ObtainJSONWebTokenPayload'
  clientMutationId?: Maybe<Scalars['String']>
  payload: Scalars['GenericScalar']
  refreshExpiresIn: Scalars['Int']
  token: Scalars['String']
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

export type PinLivestreamMessageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  message: Scalars['String']
  url: Scalars['String']
}

export type PinLivestreamMessagePayload = {
  __typename?: 'PinLivestreamMessagePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<ErrorType>
  pinnedMessage?: Maybe<PinnedMessage>
}

export type PinnedMessage = Node & {
  __typename?: 'PinnedMessage'
  asset: Asset
  /** The ID of the object */
  id: Scalars['ID']
  message: Scalars['String']
  timestamp: Scalars['DateTime']
  url: Scalars['String']
}

export type PresignedPost = {
  fields: Scalars['JSONString']
  url: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  allChatMessages?: Maybe<AssetChatMessageConnection>
  allEventRsvps?: Maybe<EventRsvpConnection>
  allEventVenues?: Maybe<EventVenueConnection>
  allEvents?: Maybe<EventConnection>
  allStoryCalendarCoverImages: Array<StoryMonthCoverImage>
  allStoryFiles?: Maybe<StoryFileConnection>
  allStoryMonths: StoryMonthConnection
  allUserLiveStreamStatuses?: Maybe<UserLiveStreamStatusConnection>
  allVices?: Maybe<Array<Maybe<Vice>>>
  conversionEventCounts?: Maybe<AnalyticsEventCounts>
  dailyStatistics?: Maybe<HitstepsDailyStatisticsData>
  event?: Maybe<Event>
  eventRsvp?: Maybe<EventRsvp>
  eventVenue?: Maybe<EventVenue>
  file?: Maybe<File>
  legacyLiveStory?: Maybe<LegacyLiveStory>
  legacyMyLiveStreamAssets?: Maybe<Array<Maybe<MuxAsset>>>
  liveStory?: Maybe<LiveStory>
  liveStreamAsset?: Maybe<Asset>
  me?: Maybe<User>
  myLiveStream?: Maybe<UserLiveStream>
  myLiveStreamAssets?: Maybe<AssetConnection>
  myLiveStreamMutes?: Maybe<MutedUserConnection>
  myMuxPlaybackIdDataToken?: Maybe<Scalars['String']>
  /** The next event */
  nextEvent?: Maybe<Event>
  node?: Maybe<Node>
  realtimeUserStatistics?: Maybe<HitstepsRealtimeData>
  story?: Maybe<Story>
  userLiveStream?: Maybe<UserLiveStream>
  userLiveStreams?: Maybe<UserLiveStreamConnection>
}

export type QueryAllChatMessagesArgs = {
  after?: InputMaybe<Scalars['String']>
  asset?: InputMaybe<Scalars['ID']>
  asset_LiveStream?: InputMaybe<Scalars['ID']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type QueryAllEventRsvpsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  event?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryAllEventVenuesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  name_Icontains?: InputMaybe<Scalars['String']>
  name_Istartswith?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryAllEventsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  endTime?: InputMaybe<Scalars['DateTime']>
  endTime_Gt?: InputMaybe<Scalars['DateTime']>
  endTime_Gte?: InputMaybe<Scalars['DateTime']>
  endTime_Lt?: InputMaybe<Scalars['DateTime']>
  endTime_Lte?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  startTime?: InputMaybe<Scalars['DateTime']>
  startTime_Gt?: InputMaybe<Scalars['DateTime']>
  startTime_Gte?: InputMaybe<Scalars['DateTime']>
  startTime_Lt?: InputMaybe<Scalars['DateTime']>
  startTime_Lte?: InputMaybe<Scalars['DateTime']>
  venue?: InputMaybe<Scalars['ID']>
}

export type QueryAllStoryCalendarCoverImagesArgs = {
  month: Scalars['Int']
  year: Scalars['Int']
}

export type QueryAllStoryFilesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryAllStoryMonthsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryAllUserLiveStreamStatusesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  livestream?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type QueryEventArgs = {
  id: Scalars['ID']
}

export type QueryEventRsvpArgs = {
  id: Scalars['ID']
}

export type QueryEventVenueArgs = {
  id: Scalars['ID']
}

export type QueryFileArgs = {
  id: Scalars['ID']
}

export type QueryLegacyMyLiveStreamAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryLiveStreamAssetArgs = {
  id: Scalars['ID']
}

export type QueryMyLiveStreamAssetsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['DateTime']>
  createdAt_Gte?: InputMaybe<Scalars['DateTime']>
  createdAt_Lte?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['ID']>
  isLive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<LivestreamsAssetStatusChoices>
}

export type QueryMyLiveStreamMutesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type QueryNodeArgs = {
  id: Scalars['ID']
}

export type QueryStoryArgs = {
  date: Scalars['Date']
  token?: InputMaybe<Scalars['String']>
}

export type QueryUserLiveStreamArgs = {
  username: Scalars['String']
}

export type QueryUserLiveStreamsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type RecordStorefrontEvent = {
  __typename?: 'RecordStorefrontEvent'
  ok?: Maybe<Scalars['Boolean']>
}

export type RefreshInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  token?: InputMaybe<Scalars['String']>
}

export type RefreshPayload = {
  __typename?: 'RefreshPayload'
  clientMutationId?: Maybe<Scalars['String']>
  payload: Scalars['GenericScalar']
  refreshExpiresIn: Scalars['Int']
  token: Scalars['String']
}

/** Removes a mute from a user's livestream  */
export type RemoveMute = {
  __typename?: 'RemoveMute'
  deletedMuteId?: Maybe<Scalars['ID']>
}

export type RemovePhoneNumber = {
  __typename?: 'RemovePhoneNumber'
  success?: Maybe<Scalars['Boolean']>
}

export type RequestPhoneLoginOtpInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  phoneNumber: Scalars['String']
}

/** Send a code to the user's phone number for login */
export type RequestPhoneLoginOtpPayload = {
  __typename?: 'RequestPhoneLoginOTPPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  message?: Maybe<Scalars['String']>
  phoneNumber: Scalars['String']
  success?: Maybe<Scalars['Boolean']>
}

export type RequestPhoneOtpInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  phoneNumber: Scalars['String']
}

/** Request an OTP code for a phone number, to be used to login or signup later */
export type RequestPhoneOtpPayload = {
  __typename?: 'RequestPhoneOTPPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  message?: Maybe<Scalars['String']>
  ok?: Maybe<Scalars['Boolean']>
  phoneNumber: Scalars['String']
}

export enum ReviewRsvpAction {
  Accept = 'accept',
  Decline = 'decline',
  UndoDecline = 'undo_decline',
}

export type ReviewRsvpMutation = {
  __typename?: 'ReviewRsvpMutation'
  eventRsvp?: Maybe<EventRsvp>
}

export type RevokeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  refreshToken?: InputMaybe<Scalars['String']>
}

export type RevokePayload = {
  __typename?: 'RevokePayload'
  clientMutationId?: Maybe<Scalars['String']>
  revoked: Scalars['Int']
}

export type SendChatMessage = {
  __typename?: 'SendChatMessage'
  error?: Maybe<Scalars['String']>
  ok: Scalars['Boolean']
}

export type SignalLiveStreamCompleteMutation = {
  __typename?: 'SignalLiveStreamCompleteMutation'
  ok?: Maybe<Scalars['Boolean']>
}

export type SignupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  password1: Scalars['String']
  password2: Scalars['String']
  phoneNumber: Scalars['String']
  username: Scalars['String']
}

/** Sign up for a new account */
export type SignupPayload = {
  __typename?: 'SignupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  password1: Scalars['String']
  password2: Scalars['String']
  phoneNumber: Scalars['String']
  token?: Maybe<Scalars['String']>
  user?: Maybe<User>
  username: Scalars['String']
}

export type StandardPresignedPost = PresignedPost & {
  __typename?: 'StandardPresignedPost'
  fields: Scalars['JSONString']
  url: Scalars['String']
}

export enum StorefrontEventType {
  AddToCart = 'ADD_TO_CART',
  StartCheckout = 'START_CHECKOUT',
}

/** A story for a particular day  */
export type Story = {
  __typename?: 'Story'
  date: Scalars['Date']
  id: Scalars['ID']
  items: Array<Maybe<StoryItem>>
}

export type StoryConfigInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  freePreviewCount: Scalars['Int']
  storyGatePassword?: InputMaybe<Scalars['String']>
}

export type StoryConfigPayload = {
  __typename?: 'StoryConfigPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  freePreviewCount: Scalars['Int']
  storyGatePassword?: Maybe<Scalars['String']>
}

export type StoryFile = File &
  LiveStoryItem &
  Node & {
    __typename?: 'StoryFile'
    banner?: Maybe<StoryFileBanner>
    blurhash?: Maybe<Scalars['String']>
    createdAt: Scalars['DateTime']
    creator?: Maybe<User>
    file?: Maybe<Scalars['String']>
    fileType?: Maybe<Scalars['String']>
    /** The ID of the object */
    id: Scalars['ID']
    interactiveAreas: StoryFileInteractiveAreaConnection
    isPreviewable?: Maybe<Scalars['Boolean']>
    metadata?: Maybe<FileMetadata>
    originalFileName?: Maybe<Scalars['String']>
    thumbnailUrl?: Maybe<Scalars['String']>
    updated: Scalars['DateTime']
  }

export type StoryFileInteractiveAreasArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type StoryFileThumbnailUrlArgs = {
  height?: InputMaybe<Scalars['Int']>
  width?: InputMaybe<Scalars['Int']>
}

export type StoryFileBanner = Node & {
  __typename?: 'StoryFileBanner'
  file: StoryFile
  /** The ID of the object */
  id: Scalars['ID']
  text: Scalars['String']
  url: Scalars['String']
}

export type StoryFileBannerInput = {
  id?: InputMaybe<Scalars['ID']>
  text: Scalars['String']
  url: Scalars['String']
}

export type StoryFileConnection = {
  __typename?: 'StoryFileConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StoryFileEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `StoryFile` and its cursor. */
export type StoryFileEdge = {
  __typename?: 'StoryFileEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<StoryFile>
}

export type StoryFileInteractiveArea = Node & {
  __typename?: 'StoryFileInteractiveArea'
  file: StoryFile
  height: Scalars['Float']
  /** The ID of the object */
  id: Scalars['ID']
  url?: Maybe<Scalars['String']>
  width: Scalars['Float']
  x: Scalars['Float']
  y: Scalars['Float']
}

export type StoryFileInteractiveAreaConnection = {
  __typename?: 'StoryFileInteractiveAreaConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StoryFileInteractiveAreaEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `StoryFileInteractiveArea` and its cursor. */
export type StoryFileInteractiveAreaEdge = {
  __typename?: 'StoryFileInteractiveAreaEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<StoryFileInteractiveArea>
}

export type StoryFileInteractiveAreaInput = {
  delete?: InputMaybe<Scalars['Boolean']>
  height: Scalars['Float']
  id?: InputMaybe<Scalars['ID']>
  url: Scalars['String']
  width: Scalars['Float']
  x: Scalars['Float']
  y: Scalars['Float']
}

export type StoryItem = ImageFile | VideoFile

/** A month in a story archive.  */
export type StoryMonth = Node & {
  __typename?: 'StoryMonth'
  id: Scalars['ID']
  /** The month of the story month. */
  month: Scalars['Int']
  /** The year of the story month. */
  year: Scalars['Int']
}

export type StoryMonthConnection = {
  __typename?: 'StoryMonthConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StoryMonthEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** The total count of story months. */
  totalCount?: Maybe<Scalars['Int']>
}

export type StoryMonthCoverImage = {
  __typename?: 'StoryMonthCoverImage'
  date: Scalars['Date']
  image?: Maybe<ImageFile>
}

/** A Relay edge containing a `StoryMonth` and its cursor. */
export type StoryMonthEdge = {
  __typename?: 'StoryMonthEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: StoryMonth
}

export type Subscription = {
  __typename?: 'Subscription'
  livestream?: Maybe<Livestream>
}

export type SubscriptionLivestreamArgs = {
  livestreamId?: InputMaybe<Scalars['ID']>
}

export type UnpinLivestreamMessage = {
  __typename?: 'UnpinLivestreamMessage'
  ok?: Maybe<Scalars['Boolean']>
}

export type UpdateUserAvatarAndUsername = {
  __typename?: 'UpdateUserAvatarAndUsername'
  errors?: Maybe<Array<Maybe<ErrorType>>>
  success?: Maybe<Scalars['Boolean']>
  token?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type UpdateUserAvatarAndUsernameInput = {
  avatar?: InputMaybe<Scalars['Upload']>
  clearAvatar?: InputMaybe<Scalars['Boolean']>
  username?: InputMaybe<Scalars['String']>
}

export type User = Node & {
  __typename?: 'User'
  assetchatmessageSet: AssetChatMessageConnection
  dateJoined: Scalars['DateTime']
  email: Scalars['String']
  firstName: Scalars['String']
  /** The ID of the object */
  id: Scalars['ID']
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']
  lastLogin?: Maybe<Scalars['DateTime']>
  lastName: Scalars['String']
  liveStream?: Maybe<UserLiveStream>
  muteduserSet: MutedUserConnection
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumbers: UserPhoneNumberConnection
  profile?: Maybe<UserProfile>
  userlivestreamstatusSet: UserLiveStreamStatusConnection
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']
}

export type UserAssetchatmessageSetArgs = {
  after?: InputMaybe<Scalars['String']>
  asset?: InputMaybe<Scalars['ID']>
  asset_LiveStream?: InputMaybe<Scalars['ID']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type UserMuteduserSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type UserPhoneNumbersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserUserlivestreamstatusSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  livestream?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

/** The user was authenticated and logged in */
export type UserAuthenticated = {
  __typename?: 'UserAuthenticated'
  payload: Scalars['JSONString']
  refreshExpiresIn?: Maybe<Scalars['Int']>
  refreshToken?: Maybe<Scalars['String']>
  token: Scalars['String']
  user: User
}

export type UserLiveStream = Node & {
  __typename?: 'UserLiveStream'
  activeAssetId?: Maybe<Scalars['String']>
  assets: AssetConnection
  connected: Scalars['Boolean']
  /** The ID of the object */
  id: Scalars['ID']
  mutedUsers: MutedUserConnection
  playbackId: Scalars['String']
  status: LivestreamsUserLiveStreamStatusChoices
  streamId: Scalars['String']
  streamKey?: Maybe<Scalars['String']>
  streamUrl?: Maybe<Scalars['String']>
  user: User
  userlivestreamstatusSet: UserLiveStreamStatusConnection
}

export type UserLiveStreamAssetsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['DateTime']>
  createdAt_Gte?: InputMaybe<Scalars['DateTime']>
  createdAt_Lte?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['ID']>
  isLive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<LivestreamsAssetStatusChoices>
}

export type UserLiveStreamMutedUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type UserLiveStreamUserlivestreamstatusSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  livestream?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type UserLiveStreamConnection = {
  __typename?: 'UserLiveStreamConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserLiveStreamEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `UserLiveStream` and its cursor. */
export type UserLiveStreamEdge = {
  __typename?: 'UserLiveStreamEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<UserLiveStream>
}

export type UserLiveStreamStatus = Node & {
  __typename?: 'UserLiveStreamStatus'
  /** The ID of the object */
  id: Scalars['ID']
  livestream: UserLiveStream
  onlineStatus: Scalars['Boolean']
  user: User
}

export type UserLiveStreamStatusConnection = {
  __typename?: 'UserLiveStreamStatusConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserLiveStreamStatusEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `UserLiveStreamStatus` and its cursor. */
export type UserLiveStreamStatusEdge = {
  __typename?: 'UserLiveStreamStatusEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<UserLiveStreamStatus>
}

/** The user needs to create an account with a sign up token */
export type UserNeedsToRegister = {
  __typename?: 'UserNeedsToRegister'
  phoneNumber: Scalars['String']
  signUpToken: Scalars['String']
}

/** User phone number type */
export type UserPhoneNumber = Node & {
  __typename?: 'UserPhoneNumber'
  /** The ID of the object */
  id: Scalars['ID']
  phoneNumber: Scalars['String']
  primary: Scalars['Boolean']
  user: User
  verified: Scalars['Boolean']
}

export type UserPhoneNumberConnection = {
  __typename?: 'UserPhoneNumberConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserPhoneNumberEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `UserPhoneNumber` and its cursor. */
export type UserPhoneNumberEdge = {
  __typename?: 'UserPhoneNumberEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<UserPhoneNumber>
}

/** The user's phone number was verified' */
export type UserPhoneNumberVerified = {
  __typename?: 'UserPhoneNumberVerified'
  phoneNumber: UserPhoneNumber
  user: User
}

export type UserProfile = Node & {
  __typename?: 'UserProfile'
  avatar?: Maybe<Scalars['String']>
  fallbackAvatar: Scalars['String']
  /** The ID of the object */
  id: Scalars['ID']
  user: User
}

export type UserProfileInput = {
  avatar?: InputMaybe<Scalars['Upload']>
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** User profile mutation  */
export type UserProfilePayload = {
  __typename?: 'UserProfilePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<ErrorType>
  userProfile?: Maybe<UserProfile>
}

export type VerifyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  token?: InputMaybe<Scalars['String']>
}

export type VerifyPayload = {
  __typename?: 'VerifyPayload'
  clientMutationId?: Maybe<Scalars['String']>
  payload: Scalars['GenericScalar']
}

export type VerifyPhoneLoginOtpInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Enter code */
  code: Scalars['String']
  /** Enter phone number */
  phoneNumber: Scalars['String']
}

/** Verify a phone number code sent via SMS for logging in */
export type VerifyPhoneLoginOtpPayload = {
  __typename?: 'VerifyPhoneLoginOTPPayload'
  clientMutationId?: Maybe<Scalars['String']>
  /** Enter code */
  code: Scalars['String']
  errors?: Maybe<Array<Maybe<ErrorType>>>
  payload?: Maybe<Scalars['JSONString']>
  phoneNumber?: Maybe<UserPhoneNumber>
  refreshExpiresIn?: Maybe<Scalars['Int']>
  refreshToken?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  token?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type VerifyPhoneNumberInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Enter code */
  code: Scalars['String']
  /** Enter phone number */
  phoneNumber: Scalars['String']
}

/** Verify a phone number with a code sent via SMS */
export type VerifyPhoneNumberPayload = {
  __typename?: 'VerifyPhoneNumberPayload'
  clientMutationId?: Maybe<Scalars['String']>
  /** Enter code */
  code: Scalars['String']
  errors?: Maybe<Array<Maybe<ErrorType>>>
  message?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<UserPhoneNumber>
  success?: Maybe<Scalars['Boolean']>
  user?: Maybe<User>
}

export type VerifyPhoneOtpInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  otp: Scalars['String']
  phoneNumber: Scalars['String']
}

/** Verify an OTP code for a phone number, logs in if the user exists, otherwise sends sign up token */
export type VerifyPhoneOtpPayload = {
  __typename?: 'VerifyPhoneOTPPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  message?: Maybe<Scalars['String']>
  otp: Scalars['String']
  phoneNumber: Scalars['String']
  result?: Maybe<VerifyPhoneOtpResult>
}

export type VerifyPhoneOtpResult =
  | UserAuthenticated
  | UserNeedsToRegister
  | UserPhoneNumberVerified

export type Vice = {
  __typename?: 'Vice'
  id: Scalars['ID']
  name: Scalars['String']
}

export type VideoFile = LegacyFile & {
  __typename?: 'VideoFile'
  coverImage?: Maybe<ImageFile>
  filename?: Maybe<Scalars['String']>
  mimeType: Scalars['String']
  size?: Maybe<Scalars['Int']>
  url: Scalars['String']
}

/** The purpose of the token */
export enum WebsocketTokenPurpose {
  Chat = 'CHAT',
  HostLiveStream = 'HOST_LIVE_STREAM',
}

export type RecordStorefrontEventMutationVariables = Exact<{
  event: StorefrontEventType
}>

export type RecordStorefrontEventMutation = {
  __typename?: 'Mutation'
  recordStorefrontEvent?: {
    __typename?: 'RecordStorefrontEvent'
    ok?: boolean | null
  } | null
}

export type EventFragment = {
  __typename?: 'Event'
  id: string
  uuid: any
  type: EventType
  isPast: boolean
  isFuture: boolean
  isHappeningNow: boolean
  startTime: any
  endTime?: any | null
  title?: string | null
  venue: {
    __typename?: 'EventVenue'
    id: string
    name: string
    city: string
    address?: string | null
    location?: any | null
    isSecret: boolean
  }
}

export type NextEventRsvpMutationVariables = Exact<{
  input: NextEventRsvpFormMutationInput
}>

export type NextEventRsvpMutation = {
  __typename?: 'Mutation'
  nextEventRsvp?: {
    __typename?: 'NextEventRsvpFormMutationPayload'
    clientMutationId?: string | null
    eventRsvp?: { __typename?: 'EventRsvp'; id: string } | null
    errors?: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type GetNextEventQueryVariables = Exact<{ [key: string]: never }>

export type GetNextEventQuery = {
  __typename?: 'Query'
  nextEvent?: {
    __typename?: 'Event'
    id: string
    uuid: any
    type: EventType
    isPast: boolean
    isFuture: boolean
    isHappeningNow: boolean
    startTime: any
    endTime?: any | null
    title?: string | null
    venue: {
      __typename?: 'EventVenue'
      id: string
      name: string
      city: string
      address?: string | null
      location?: any | null
      isSecret: boolean
    }
  } | null
}

export type InfluencerProfileMutationVariables = Exact<{
  input: InfluencerProfileInput
}>

export type InfluencerProfileMutation = {
  __typename?: 'Mutation'
  influencerProfile?: {
    __typename?: 'InfluencerProfilePayload'
    influencerProfile?: { __typename?: 'InfluencerProfile'; id: string } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    }>
  } | null
}

export type AlphasLiveForeverLeadMutationVariables = Exact<{
  input: AlphasLiveForeverLeadFormMutationInput
}>

export type AlphasLiveForeverLeadMutation = {
  __typename?: 'Mutation'
  alphasLiveForeverLead?: {
    __typename?: 'AlphasLiveForeverLeadFormMutationPayload'
    ok: boolean
    message?: string | null
  } | null
}

export type AprilWarehouseLeadMutationVariables = Exact<{
  input: AprilWarehouseLeadFormMutationInput
}>

export type AprilWarehouseLeadMutation = {
  __typename?: 'Mutation'
  aprilWarehouseLead?: {
    __typename?: 'AprilWarehouseLeadFormMutationPayload'
    ok: boolean
    message?: string | null
  } | null
}

export type GetAllVicesQueryVariables = Exact<{ [key: string]: never }>

export type GetAllVicesQuery = {
  __typename?: 'Query'
  allVices?: Array<{
    __typename?: 'Vice'
    id: string
    name: string
  } | null> | null
}

export const EventFragmentDoc = gql`
  fragment Event on Event {
    id
    uuid
    type
    isPast
    isFuture
    isHappeningNow
    startTime
    endTime
    venue {
      id
      name
      city
      address
      location
      isSecret
    }
    title
  }
`
export const RecordStorefrontEventDocument = gql`
  mutation RecordStorefrontEvent($event: StorefrontEventType!) {
    recordStorefrontEvent(event: $event) {
      ok
    }
  }
`
export type RecordStorefrontEventMutationFn = Apollo.MutationFunction<
  RecordStorefrontEventMutation,
  RecordStorefrontEventMutationVariables
>

/**
 * __useRecordStorefrontEventMutation__
 *
 * To run a mutation, you first call `useRecordStorefrontEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordStorefrontEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordStorefrontEventMutation, { data, loading, error }] = useRecordStorefrontEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useRecordStorefrontEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecordStorefrontEventMutation,
    RecordStorefrontEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RecordStorefrontEventMutation,
    RecordStorefrontEventMutationVariables
  >(RecordStorefrontEventDocument, options)
}
export type RecordStorefrontEventMutationHookResult = ReturnType<
  typeof useRecordStorefrontEventMutation
>
export type RecordStorefrontEventMutationResult =
  Apollo.MutationResult<RecordStorefrontEventMutation>
export type RecordStorefrontEventMutationOptions = Apollo.BaseMutationOptions<
  RecordStorefrontEventMutation,
  RecordStorefrontEventMutationVariables
>
export const NextEventRsvpDocument = gql`
  mutation NextEventRsvp($input: NextEventRsvpFormMutationInput!) {
    nextEventRsvp(input: $input) {
      eventRsvp {
        id
      }
      errors {
        field
        messages
      }
      clientMutationId
    }
  }
`
export type NextEventRsvpMutationFn = Apollo.MutationFunction<
  NextEventRsvpMutation,
  NextEventRsvpMutationVariables
>

/**
 * __useNextEventRsvpMutation__
 *
 * To run a mutation, you first call `useNextEventRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNextEventRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nextEventRsvpMutation, { data, loading, error }] = useNextEventRsvpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNextEventRsvpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NextEventRsvpMutation,
    NextEventRsvpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    NextEventRsvpMutation,
    NextEventRsvpMutationVariables
  >(NextEventRsvpDocument, options)
}
export type NextEventRsvpMutationHookResult = ReturnType<
  typeof useNextEventRsvpMutation
>
export type NextEventRsvpMutationResult =
  Apollo.MutationResult<NextEventRsvpMutation>
export type NextEventRsvpMutationOptions = Apollo.BaseMutationOptions<
  NextEventRsvpMutation,
  NextEventRsvpMutationVariables
>
export const GetNextEventDocument = gql`
  query GetNextEvent {
    nextEvent {
      ...Event
    }
  }
  ${EventFragmentDoc}
`

/**
 * __useGetNextEventQuery__
 *
 * To run a query within a React component, call `useGetNextEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextEventQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNextEventQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNextEventQuery,
    GetNextEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetNextEventQuery, GetNextEventQueryVariables>(
    GetNextEventDocument,
    options
  )
}
export function useGetNextEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNextEventQuery,
    GetNextEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetNextEventQuery, GetNextEventQueryVariables>(
    GetNextEventDocument,
    options
  )
}
export type GetNextEventQueryHookResult = ReturnType<
  typeof useGetNextEventQuery
>
export type GetNextEventLazyQueryHookResult = ReturnType<
  typeof useGetNextEventLazyQuery
>
export type GetNextEventQueryResult = Apollo.QueryResult<
  GetNextEventQuery,
  GetNextEventQueryVariables
>
export const InfluencerProfileDocument = gql`
  mutation InfluencerProfile($input: InfluencerProfileInput!) {
    influencerProfile(input: $input) {
      influencerProfile {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
export type InfluencerProfileMutationFn = Apollo.MutationFunction<
  InfluencerProfileMutation,
  InfluencerProfileMutationVariables
>

/**
 * __useInfluencerProfileMutation__
 *
 * To run a mutation, you first call `useInfluencerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInfluencerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [influencerProfileMutation, { data, loading, error }] = useInfluencerProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInfluencerProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InfluencerProfileMutation,
    InfluencerProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InfluencerProfileMutation,
    InfluencerProfileMutationVariables
  >(InfluencerProfileDocument, options)
}
export type InfluencerProfileMutationHookResult = ReturnType<
  typeof useInfluencerProfileMutation
>
export type InfluencerProfileMutationResult =
  Apollo.MutationResult<InfluencerProfileMutation>
export type InfluencerProfileMutationOptions = Apollo.BaseMutationOptions<
  InfluencerProfileMutation,
  InfluencerProfileMutationVariables
>
export const AlphasLiveForeverLeadDocument = gql`
  mutation AlphasLiveForeverLead(
    $input: AlphasLiveForeverLeadFormMutationInput!
  ) {
    alphasLiveForeverLead(input: $input) {
      ok
      message
    }
  }
`
export type AlphasLiveForeverLeadMutationFn = Apollo.MutationFunction<
  AlphasLiveForeverLeadMutation,
  AlphasLiveForeverLeadMutationVariables
>

/**
 * __useAlphasLiveForeverLeadMutation__
 *
 * To run a mutation, you first call `useAlphasLiveForeverLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlphasLiveForeverLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alphasLiveForeverLeadMutation, { data, loading, error }] = useAlphasLiveForeverLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlphasLiveForeverLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AlphasLiveForeverLeadMutation,
    AlphasLiveForeverLeadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AlphasLiveForeverLeadMutation,
    AlphasLiveForeverLeadMutationVariables
  >(AlphasLiveForeverLeadDocument, options)
}
export type AlphasLiveForeverLeadMutationHookResult = ReturnType<
  typeof useAlphasLiveForeverLeadMutation
>
export type AlphasLiveForeverLeadMutationResult =
  Apollo.MutationResult<AlphasLiveForeverLeadMutation>
export type AlphasLiveForeverLeadMutationOptions = Apollo.BaseMutationOptions<
  AlphasLiveForeverLeadMutation,
  AlphasLiveForeverLeadMutationVariables
>
export const AprilWarehouseLeadDocument = gql`
  mutation AprilWarehouseLead($input: AprilWarehouseLeadFormMutationInput!) {
    aprilWarehouseLead(input: $input) {
      ok
      message
    }
  }
`
export type AprilWarehouseLeadMutationFn = Apollo.MutationFunction<
  AprilWarehouseLeadMutation,
  AprilWarehouseLeadMutationVariables
>

/**
 * __useAprilWarehouseLeadMutation__
 *
 * To run a mutation, you first call `useAprilWarehouseLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAprilWarehouseLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aprilWarehouseLeadMutation, { data, loading, error }] = useAprilWarehouseLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAprilWarehouseLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AprilWarehouseLeadMutation,
    AprilWarehouseLeadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AprilWarehouseLeadMutation,
    AprilWarehouseLeadMutationVariables
  >(AprilWarehouseLeadDocument, options)
}
export type AprilWarehouseLeadMutationHookResult = ReturnType<
  typeof useAprilWarehouseLeadMutation
>
export type AprilWarehouseLeadMutationResult =
  Apollo.MutationResult<AprilWarehouseLeadMutation>
export type AprilWarehouseLeadMutationOptions = Apollo.BaseMutationOptions<
  AprilWarehouseLeadMutation,
  AprilWarehouseLeadMutationVariables
>
export const GetAllVicesDocument = gql`
  query GetAllVices {
    allVices {
      id
      name
    }
  }
`

/**
 * __useGetAllVicesQuery__
 *
 * To run a query within a React component, call `useGetAllVicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllVicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllVicesQuery,
    GetAllVicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllVicesQuery, GetAllVicesQueryVariables>(
    GetAllVicesDocument,
    options
  )
}
export function useGetAllVicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllVicesQuery,
    GetAllVicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllVicesQuery, GetAllVicesQueryVariables>(
    GetAllVicesDocument,
    options
  )
}
export type GetAllVicesQueryHookResult = ReturnType<typeof useGetAllVicesQuery>
export type GetAllVicesLazyQueryHookResult = ReturnType<
  typeof useGetAllVicesLazyQuery
>
export type GetAllVicesQueryResult = Apollo.QueryResult<
  GetAllVicesQuery,
  GetAllVicesQueryVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    File: ['StoryFile'],
    LegacyFile: ['ImageFile', 'VideoFile'],
    LegacyStoryItem: ['ImageFile', 'VideoFile'],
    LiveStoryItem: ['LiveStoryFallbackItem', 'StoryFile'],
    LivestreamEvent: [
      'AssetChatMessage',
      'LivestreamHostEvent',
      'LivestreamMessagePinEvent',
      'LivestreamVideoEvent',
    ],
    Node: [
      'Asset',
      'AssetChatMessage',
      'Event',
      'EventRsvp',
      'EventVenue',
      'MutedUser',
      'MuxAsset',
      'PinnedMessage',
      'StoryFile',
      'StoryFileBanner',
      'StoryFileInteractiveArea',
      'StoryMonth',
      'User',
      'UserLiveStream',
      'UserLiveStreamStatus',
      'UserPhoneNumber',
      'UserProfile',
    ],
    PresignedPost: ['StandardPresignedPost'],
    StoryItem: ['ImageFile', 'VideoFile'],
    VerifyPhoneOTPResult: [
      'UserAuthenticated',
      'UserNeedsToRegister',
      'UserPhoneNumberVerified',
    ],
  },
}
export default result
