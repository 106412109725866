import React, { FC } from 'react'

interface MultiColorAvailableBannerProps {}

const MultiColorAvailableBanner: FC<MultiColorAvailableBannerProps> = () => {
  return (
    <div className="text-center py-3 -mb-2 max-w-sm mx-auto">
      <p className="text-xs font-semibold text-[#BEBEBE] uppercase">
        Available In 12 Colors
      </p>
      <div className="flex justify-center mt">
        <div className="w-4 h-4 bg-[#ECECEC]"></div>
        <div className="w-4 h-4 bg-[#D3D3D3]"></div>
        <div className="w-4 h-4 bg-[#DBD1C8]"></div>
        <div className="w-4 h-4 bg-[#BC252C]"></div>
        <div className="w-4 h-4 bg-[#574088]"></div>
        <div className="w-4 h-4 bg-[#1B70B8]"></div>
        <div className="w-4 h-4 bg-[#37425E]"></div>
        <div className="w-4 h-4 bg-[#727C59]"></div>
        <div className="w-4 h-4 bg-[#00CF75]"></div>
        <div className="w-4 h-4 bg-[#7A7A78]"></div>
        <div className="w-4 h-4 bg-[#312F30]"></div>
        <div className="w-4 h-4 bg-[#FF99D1]"></div>
      </div>
    </div>
  )
}

export default MultiColorAvailableBanner
