import React from 'react'
import classNames from 'classnames'

interface IProps {
  top: React.ReactNode
  bottom?: React.ReactNode
}

const ProductTileLayout: React.FC<IProps> = ({ top, bottom }) => {
  return (
    <article className={'space-y-6'}>
      <div className={classNames('bg-neutral-200')}>
        {top}
      </div>
      {bottom && (
        <div className={classNames('w-[87.5%] mx-auto')}>{bottom}</div>
      )}
    </article>
  )
}

export default ProductTileLayout
