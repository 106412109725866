import React from 'react'

interface IProps {
  heading?: string
  children: React.ReactNode
  footer?: React.ReactNode
}

const ProductTileSet: React.FC<IProps> = ({ heading, children, footer }) => {
  return (
    <div>
      {heading && (
        <div className="text-center mb-5">
          <h2 className="font-bold font-sans-condensed text-2xl md:text-3xl">
            {heading}
          </h2>
        </div>
      )}
      <div className="grid gap-x-1 gap-y-6 grid-cols-2 lg:grid-cols-4">
        {children}
      </div>
      {footer && <div>{footer}</div>}
    </div>
  )
}

export default ProductTileSet
