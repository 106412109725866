import { gql } from '@apollo/client'
import { productPricesFragment, multipleChoiceOptionFragment } from '.'

const productVariantsFragment = gql`
  fragment productVariants on Product {
    variants {
      edges {
        node {
          entityId
          defaultImage {
            urlOriginal
            altText
            isDefault
          }
          prices {
            ...productPrices
          }
          inventory {
            aggregated {
              availableToSell
              warningLevel
            }
            isInStock
          }
          productOptions {
            edges {
              node {
                __typename
                entityId
                displayName
                ...multipleChoiceOption
              }
            }
          }
        }
      }
    }
  }
  ${multipleChoiceOptionFragment}
  ${productPricesFragment}
`

export default productVariantsFragment
