// @ts-ignore
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'

// import { recordPageview } from '@/lib/google-analytics'
import { useStore } from '@/stores'

const useRouteChangeHandlers = () => {
  const router = useRouter()
  const { closeMainMenu, closeCart, closeCodeVault } = useStore()

  useEffect(() => {
    const handleRouteChangeStart = () => {
      closeCart()
      closeMainMenu()
      closeCodeVault()

      NProgress.start()
    }
    const handleRouteChangeComplete = (url: string) => {
      // recordPageview(url)
      if (typeof window !== 'undefined') {
        ;(window as any).ga('send', 'pageview')
      }

      NProgress.done()
    }

    const handleRouteChangeError = (err: any, url: string) => {
      if (!err.cancelled) {
        Sentry.captureException(err)
      }

      NProgress.done()
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [closeMainMenu, closeCart, router.events, closeCodeVault])
}

export default useRouteChangeHandlers
