import { ApolloClient, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import cache from './storefront-client-cache'

/**
 * https://www.apollographql.com/blog/apollo-client/next-js/next-js-getting-started/
 */
const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_STOREFRONT_API_URL,
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${process.env.NEXT_PUBLIC_STOREFRONT_API_TOKEN}`,
    },
  }
})

const storefrontClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  uri: process.env.BIGCOMMERCE_STOREFRONT_API_URL,
  cache,
  link: authLink.concat(httpLink),
})

export default storefrontClient
