import { gql } from '@apollo/client'

const swatchOptionFragment = gql`
  fragment swatchOption on SwatchOptionValue {
    isDefault
    hexColors
  }
`

export default swatchOptionFragment
