import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import results from '@/generated/graphql'
import { errorLink } from './links'

/**
 * https://www.apollographql.com/blog/apollo-client/next-js/next-js-getting-started/
 */
const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      // authorization: `Bearer ${process.env.}`,
    },
  }
})

const backendClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  cache: new InMemoryCache({ possibleTypes: results.possibleTypes }),
  link: ApolloLink.from([authLink, errorLink, httpLink]),
})

export default backendClient
