import { FetchPolicy, gql, useQuery } from '@apollo/client'
import { storefrontClient } from '@/lib/apollo'

import { productConnectionFragment } from '@/features/products/api/storefront/fragments'
import {
  Category,
  ProductEdge,
  Query,
} from '@/types/bigcommerce/generated/graphql'

// TODO: Clean up this entire file
// TODO: TYPE NODE WITH A RECURSIVE PARTIAL AS CATEGORY

const GET_CATEGORY = gql`
  query GetCategory(
    $path: String!
    $hasLocale: Boolean = false
    $locale: String = "null"
    $productAfter: String = ""
    $productFirst: Int = 12
    $productSortBy: CategoryProductSort = DEFAULT
  ) {
    site {
      route(path: $path) {
        node {
          __typename
          id
          ... on Category {
            name
            path
            entityId
            defaultImage {
              urlOriginal
              altText
            }
            seo {
              pageTitle
              metaKeywords
              metaDescription
            }
            products(
              first: $productFirst
              sortBy: $productSortBy
              after: $productAfter
            ) {
              __typename
              ...productConnection
            }
          }
        }
      }
    }
  }
  ${productConnectionFragment}
`

const GET_CATEGORY_ONLY_PRODUCTS = gql`
  query GetCategoryOnlyProducts(
    $path: String!
    $hasLocale: Boolean = false
    $locale: String = "null"
    $after: String
    $first: Int = 12
    $sortBy: CategoryProductSort = DEFAULT
  ) {
    site {
      route(path: $path) {
        node {
          __typename
          id
          ... on Category {
            products(first: $first, sortBy: $sortBy, after: $after) {
              __typename
              ...productConnection
            }
          }
        }
      }
    }
  }
  ${productConnectionFragment}
`

const GET_CATEGORY_WITHOUT_PRODUCTS = gql`
  query GetCategoryWithoutProducts($path: String!) {
    site {
      route(path: $path) {
        node {
          __typename
          id
          ... on Category {
            name
            path
            entityId
            defaultImage {
              urlOriginal
              altText
            }
          }
        }
      }
    }
  }
`

// TODO: Types
const useCategory = ({
  path,
  after,
  first,
  sortBy,
}: {
  path: string
  after?: string
  first?: number
  sortBy?: string
}) => {
  // TODO: Type "Node" with recursive partial as "Category"
  return useQuery<Query>(GET_CATEGORY, {
    variables: {
      path,
      hasLocale: false,
      locale: 'null',
      productAfter: after,
      productFirst: first,
      productSortBy: sortBy,
    },
  })
}

const getCategoryOnlyProducts = ({
  variables: { slug, first, after, sortBy },
  fetchPolicy,
}: {
  variables: any
  fetchPolicy?: FetchPolicy
}) => {
  return storefrontClient.query<Query>({
    query: GET_CATEGORY_ONLY_PRODUCTS,
    variables: {
      path: `/categories/${slug}`,
      after,
      first,
      sortBy,
    },
    fetchPolicy,
  })
}

const getCategoryWithoutProducts = (slug: string) => {
  return storefrontClient.query<Query>({
    query: GET_CATEGORY_WITHOUT_PRODUCTS,
    variables: {
      path: `/categories/${slug}`,
    },
  })
}

const getCategoryWithProducts = async ({
  slug,
  productCount,
}: {
  slug: string
  productCount: number
}) => {
  const categoryQueryResponse = await getCategoryWithoutProducts(slug)

  const allProductEdges: ProductEdge[] = []
  const getAllProductEdges = async (count: number, after?: string) => {
    const first = count > 50 ? 50 : count
    const { data } = await getCategoryOnlyProducts({
      variables: { slug, first, after },
      fetchPolicy: 'no-cache',
    })

    const {
      products: { pageInfo, edges },
    } = data.site.route.node as Category

    allProductEdges.push(...(edges as ProductEdge[])!)
    const productsRemaining = productCount - allProductEdges.length
    if (allProductEdges.length >= productCount) {
      return allProductEdges
    }

    if (pageInfo.hasNextPage) {
      const count = productsRemaining > 50 ? 50 : productsRemaining
      await getAllProductEdges(count, pageInfo.endCursor!)
    }
  }

  await getAllProductEdges(productCount)
  // TODO: Handle this with lodash ._set ?
  const categoryWithProducts = {
    ...categoryQueryResponse,
    data: {
      ...categoryQueryResponse.data,
      site: {
        ...categoryQueryResponse.data.site,
        route: {
          ...categoryQueryResponse.data.site.route,
          node: {
            ...categoryQueryResponse.data.site.route.node,
            products: {
              pageInfo: {
                hasNextPage: false,
                endCursor: '',
              },
              edges: allProductEdges,
            },
          },
        },
      },
    },
  }

  return categoryWithProducts
}

export {
  useCategory,
  getCategoryWithoutProducts,
  getCategoryWithProducts,
  GET_CATEGORY_WITHOUT_PRODUCTS,
}
export default GET_CATEGORY
