import { gql } from '@apollo/client'

const productPricesFragment = gql`
  fragment productPrices on Prices {
    price {
      value
      currencyCode
    }
    priceRange {
      min {
        value
      }
      max {
        value
      }
    }
    basePrice {
      value
      currencyCode
    }
    salePrice {
      value
      currencyCode
    }
  }
`

export default productPricesFragment
