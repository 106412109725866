const recordAddToCart = (line_item: any) => {
  // UA
  const ga = (window as any).ga || {}
  ga('send', {
    hitType: 'event',
    eventCategory: 'EnhancedEcommerce',
    eventAction: 'Product Added',
  })

  // GA4
  const gtag = (window as any).gtag || {}
  gtag('event', 'add_to_cart')
}

export default recordAddToCart
