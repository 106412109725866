import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import result from '@/generated/bigcommerce/graphql'

const cache = new InMemoryCache({
  possibleTypes: result.possibleTypes,
  typePolicies: {
    SearchProducts: {
      fields: {
        products: relayStylePagination(),
      },
    },
    Node: {
      fields: {
        products: relayStylePagination(),
      },
    },
  },
})

export default cache
