import { gql } from '@apollo/client'
import productPricesFragment from './product-prices'
import multipleChoiceOptionFragment from './multiple-choice-option'

export const productInfoFragment = gql`
  fragment productInfo on Product {
    id
    entityId
    name
    path
    brand {
      entityId
    }
    description
    plainTextDescription
    sku
    metafields(namespace: "LINDAFINEGOLD") {
      edges {
        node {
          __typename
          id
          key
          value
        }
      }
    }
    seo {
      pageTitle
      metaDescription
      metaKeywords
    }
    prices {
      ...productPrices
    }
    inventory {
      isInStock
      hasVariantInventory
    }
    defaultImage {
      __typename
      urlOriginal
      url320wide: url(width: 320)
      url640wide: url(width: 640)
      url960wide: url(width: 960)
      url1280wide: url(width: 1280)
    }
    images {
      __typename
      edges {
        __typename
        node {
          __typename
          urlOriginal
          altText
          isDefault
          url320wide: url(width: 320)
          url640wide: url(width: 640)
          url960wide: url(width: 960)
          url1280wide: url(width: 1280)
        }
      }
    }
    variants {
      edges {
        node {
          entityId
          defaultImage {
            urlOriginal
            altText
            isDefault
          }
        }
      }
    }
    productOptions {
      edges {
        node {
          __typename
          entityId
          displayName
          ...multipleChoiceOption
        }
      }
    }
    categories {
      edges {
        node {
          name
        }
      }
    }
    localeMeta: metafields(namespace: $locale, keys: ["name", "description"])
      @include(if: $hasLocale) {
      edges {
        node {
          key
          value
        }
      }
    }
  }

  ${productPricesFragment}
  ${multipleChoiceOptionFragment}
`

export default productInfoFragment
