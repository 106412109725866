import React, { FC } from 'react'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'

import type {
  ProductOptionConnection,
  VariantConnection,
  VariantEdge,
} from '@/types/bigcommerce/generated/graphql'

import { useAddItem } from '@/features/cart/hooks'
import { AddToCartFormData } from '../types'

import ProductOptionRadioGroup from './ProductOptionRadioGroup'

interface RadioGroupCartAddFormProps {
  productId: number
  options?: ProductOptionConnection
  variants?: VariantConnection
  unlimitedInventory?: boolean
  onSuccess?: () => void
}

// Utility function to extract the size label from variant options
const getSizeLabel = (variant: VariantEdge | null): string => {
  const options = variant?.node.productOptions?.edges || []
  if (options.length === 0) return '' // No options available

  // Find an option that includes "size" in the displayName (case insensitive)
  const sizeOption = options.find((option) =>
    option?.node.displayName.toLowerCase().includes('size')
  )

  if (!sizeOption) return '' // If no size option found, return empty

  // @ts-expect-error values does work
  const sizeLabels = sizeOption.node.values.edges.map(
    (edge: any) => edge.node.label
  )

  return sizeLabels[0] || '' // Return the first size label or empty string
}

const RadioGroupCartAddForm: FC<RadioGroupCartAddFormProps> = ({
  onSuccess,
  productId,
  options,
  unlimitedInventory,
  variants,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<AddToCartFormData>()
  const { addLineItem } = useAddItem({
    onSuccess,
  })
  const onSubmit = handleSubmit(async (data) => {
    return addLineItem(data)
  })

  const radioGroupOptions = variants!.edges!.map((variant) => ({
    disabled: !unlimitedInventory && !variant?.node.inventory?.isInStock,
    value: variant?.node.entityId!,
    name: getSizeLabel(variant), // Use the utility function to get the correct size label
  }))

  return (
    <form
      method={'post'}
      action={'/api/cart'}
      encType={'multipart/form-data'}
      onSubmit={onSubmit}
    >
      <input type={'hidden'} {...register('action', { value: 'add' })} />
      <input type={'hidden'} {...register('quantity', { value: 1 })} />
      <input
        type={'hidden'}
        {...register('product_id', { value: productId })}
      />
      <div className={'pt-4 pb-8 flex flex-col space-y-4'}>
        <Controller
          name={'variant_id'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ProductOptionRadioGroup options={radioGroupOptions} {...field} />
          )}
        />

        <button
          className={classNames('button button--primary', {
            'button--loading': isSubmitting,
          })}
          disabled={isSubmitting}
        >
          Add to Cart
        </button>
      </div>
    </form>
  )
}

export default RadioGroupCartAddForm
