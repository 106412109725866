import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { Builder, withChildren } from '@builder.io/react'

import { Category, Query } from '@/types/bigcommerce/generated/graphql'
import { ProductTileSet, ProductTile } from '@/features/products/components'

import { getCategoryWithProducts } from '../../api/get-category'

interface IProps {
  categorySlug: string
  productCount?: number
  withHeader?: boolean
  withFooter?: boolean
  data?: Query
}

const CategoryProductTileSet: React.FC<IProps> = ({
  categorySlug,
  productCount = 4,
  withHeader,
  withFooter,
  data: propData,
  ...props
}) => {
  const [data, setData] = useState<any>(propData || null)

  // TODO: https://web.dev/dom-size/?utm_source=lighthouse&utm_medium=devtools
  // https://forum.builder.io/t/getasyncprops-not-working-in-editor-preview-with-localhost/1551
  // https://www.npmjs.com/package/@builder.io/utils/v/1.0.3
  useEffect(() => {
    if (!propData) {
      // Fetch the data only if it was not passed as a prop
      getCategoryWithProducts({ slug: categorySlug, productCount })
        .then((fetchedData) => {
          setData(fetchedData.data)
        })
        .catch((e) => {
          //
        })
    }
  }, [categorySlug, productCount, propData])

  const category = data?.site?.route?.node as Category

  return (
    <>
      <ProductTileSet
        heading={withHeader && category ? category.name : undefined}
      >
        {category?.products?.edges?.map((product) => (
          <ProductTile key={product!.node.entityId} product={product!.node} />
        ))}
      </ProductTileSet>
      {withFooter && (
        <Link href={`/categories/${categorySlug}`} prefetch={false}>
          <a className="text-center mx-auto button block my-4 button--large">
            View Collection
          </a>
        </Link>
      )}
    </>
  )
}

Builder.registerComponent(withChildren(CategoryProductTileSet), {
  name: 'CategoryProductTileSet',
  inputs: [
    /*{
      name: 'category',
      type: 'BigCommerceCategory',
      defaultValue: null,
    },*/
    {
      name: 'categorySlug',
      type: 'string',
      friendlyName: 'Category Slug',
      defaultValue: 'FEATURED',
    },
    {
      name: 'productCount',
      type: 'number',
      friendlyName: 'Product Count',
      defaultValue: 4,
    },
    {
      name: 'withHeader',
      type: 'boolean',
      friendlyName: 'Show Header',
      defaultValue: true,
    },
    {
      name: 'withFooter',
      type: 'boolean',
      friendlyName: 'Show Footer',
      defaultValue: true,
    },
  ],
})

export default CategoryProductTileSet
