import React, { FC, useCallback, useMemo, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useStore } from '@/stores'
import type { Product } from '@/types/bigcommerce/generated/graphql'

import { useProduct } from '../../hooks'
import RadioGroupCartAddForm from '../RadioGroupCartAddForm'
import ProductDetails from '../ProductDetails'
import SoldOutAlertBanner from '../SoldOutAlertBanner'
import ProductTileAlertBanner from '../ProductTileAlertBanner'

import ProductTileLayout from './ProductTileLayout'
import ActionButton from './ActionButton'
import AddToCartFormDrawer from './AddToCartFormDrawer'
import ActionButtonCartAddForm from './ActionButtonCartAddForm'
import MultiColorAvailableBanner from './MultiColorAvailableBanner'

interface ProductTileProps {
  product: Product
}

const ProductTile: FC<ProductTileProps> = ({ product }) => {
  const {
    soldOut,
    path,
    images,
    title,
    subtitle,
    isPasswordProtected,
    originalPriceIfOnSale,
    unlimitedInventory,
    videoUrl,
  } = useProduct(product)

  const router = useRouter()
  const { themeSettings } = useStore()
  const [addToCartViewOpen, setAddToCartViewOpen] = useState(false)
  const [isLocked, setIsLocked] = useState(isPasswordProtected)

  const closeAddToCartView = useCallback(() => {
    setAddToCartViewOpen(false)
  }, [])

  const hasVariants = useMemo(() => {
    return product.variants?.edges && product.variants.edges.length > 1
  }, [product.variants.edges])

  const defaultVariantId = useMemo(() => {
    // If there is only one variant, use it as the default
    return product.variants?.edges?.length === 1
      ? product.variants?.edges?.[0]?.node.entityId
      : undefined
  }, [product.variants])

  const handleHasVariantActionButtonClick = useCallback(async () => {
    setAddToCartViewOpen(true)
  }, [])

  const handleUnlockActionButtonClick = useCallback(() => {
    router.push(path)
  }, [path, router])

  const imageSrc = useMemo(() => {
    if (isLocked) {
      return themeSettings.lockedProductImage
    }

    const mainImageUrl = images[0]?.url640wide

    // BigCommerce's image manager doesn't properly scale gif images. It actually increases in filesize.
    // So, we'll just use the original image for gifs.
    if (mainImageUrl?.endsWith('.gif')) {
      return images[0]?.url
    }

    return mainImageUrl
  }, [isLocked, images, themeSettings.lockedProductImage])

  return (
    <>
      <ProductTileLayout
        top={
          <div className={'relative'}>
            <Link href={path} prefetch={false}>
              <a>
                <Image
                  // TODO: Create a bigcommerce image loader that can handle the image url and manipuate width and quality
                  // TODO: Short term create src set with all the available images
                  src={imageSrc || 'test.jpg'}
                  width={640}
                  // keep 3/4 aspect ratio
                  height={853.33}
                  layout={'responsive'}
                  unoptimized
                  alt={title}
                  objectFit={'cover'}
                />
              </a>
            </Link>
            {(isLocked || !soldOut) && (
              <>
                {addToCartViewOpen && (
                  <div
                    className={
                      'absolute inset-x-5 bottom-5 bg-white px-4 pt-4 z-20 border border-neutral-800 hidden sm:block'
                    }
                  >
                    <div className={'flex justify-between'}>
                      <div
                        className={'font-sans-condensed text-sm font-medium'}
                      >
                        Select Size
                      </div>
                      <button onClick={closeAddToCartView}>
                        <IoMdClose />
                      </button>
                    </div>
                    <div>
                      <RadioGroupCartAddForm
                        unlimitedInventory={unlimitedInventory}
                        onSuccess={closeAddToCartView}
                        productId={product.entityId}
                        options={product.productOptions}
                        variants={product.variants}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={classNames(
                    'absolute bottom-5 right-4 sm:right-6 z-[2]',
                    { invisible: false }
                  )}
                >
                  {isLocked && (
                    <ActionButton
                      onClick={handleUnlockActionButtonClick}
                      mode={'unlock'}
                    />
                  )}
                  {!isLocked && hasVariants && (
                    <ActionButton
                      onClick={handleHasVariantActionButtonClick}
                      mode={'add-to-cart'}
                    />
                  )}
                  {!isLocked && !hasVariants && (
                    <ActionButtonCartAddForm
                      productId={product.entityId}
                      defaultVariantId={defaultVariantId}
                    />
                  )}
                </div>
              </>
            )}
            <div
              className={classNames(
                'absolute inset-x-0 w-[87.5%] mx-auto select-none cursor-default -bottom-3 z-[1]'
              )}
            >
              {!isLocked && soldOut ? (
                <SoldOutAlertBanner />
              ) : (
                <>
                  <MultiColorAvailableBanner />
                  {/* <ProductTileAlertBanner /> */}
                </>
              )}
            </div>
          </div>
        }
        bottom={
          <ProductDetails
            title={
              isLocked ? '** YOU NEED A PASSWORD TO ACCESS THIS ITEM **' : title
            }
            subtitle={isLocked ? '' : subtitle}
            path={path}
            mode={'product-tile'}
            price={isLocked ? undefined : product.prices?.price.value}
            originalPrice={isLocked ? undefined : originalPriceIfOnSale}
          />
        }
      />
      {addToCartViewOpen &&
        !isLocked && ( // TODO: Hide on desktop, use a resizeobserver?
          <AddToCartFormDrawer
            key={product.entityId}
            title={'Select Size'}
            open
            onClose={closeAddToCartView}
            product={product}
          />
        )}
    </>
  )
}

export default ProductTile
