import React, { useEffect, useState } from 'react'
import type { Image } from '@framework/schema'
import classNames from 'classnames'
import Link from 'next/link'
import { Builder, withChildren } from '@builder.io/react'

import { getCategoryWithoutProducts } from '../../api/get-category'
import CategoryListItem from './CategoryListItem'

export type Category = {
  id: string
  slug: string
  image?: Image
}

interface IProps {
  categories: any[]
  type?: 'index' | 'pageSection'
  alwaysTwoColumns?: boolean
}

const CategoryList: React.FC<IProps> = ({
  categories: categoriesProp,
  type = 'pageSection',
  alwaysTwoColumns = false,
  ...props
}) => {
  const [categories, setCategories] = useState<Category[] | null>(
    categoriesProp || null
  )

  useEffect(() => {
    if (!categoriesProp?.length) {
      const newCategories: Category[] = []
      // @ts-ignore
      const slugs = props.slugs?.map((slug: any) => slug)
      if (slugs) {
        for (const { slug } of slugs) {
          getCategoryWithoutProducts(slug)
            .then(({ data }) => {
              const category = data.site.route.node as any
              newCategories.push({
                id: category.entityId,
                image: category.defaultImage,
                slug,
              })
              setCategories(newCategories)
            })
            .catch((e) => {
              console.error(e)
            })
        }
      }
    }
    // @ts-ignore
  }, [categoriesProp, props?.slugs])

  return (
    <div
      className={classNames('grid gap-1', {
        'grid-cols-2': alwaysTwoColumns,
        'sm:grid-cols-2': !alwaysTwoColumns && type === 'pageSection',
        'grid-cols-2 md:grid-cols-3': !alwaysTwoColumns && type === 'index',
      })}
    >
      {categories?.map(({ id, image, slug }) => (
        <Link
          key={id}
          prefetch={false}
          href={{ pathname: '/categories/[slug]', query: { slug } }}
        >
          <a>
            <CategoryListItem image={image} />
          </a>
        </Link>
      ))}
    </div>
  )
}

Builder.registerComponent(withChildren(CategoryList), {
  name: 'CategoryList',
  inputs: [
    {
      name: 'alwaysTwoColumns',
      type: 'boolean',
      defaultValue: false,
    },
    /*{
      name: 'categories',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'category',
          type: 'BigCommerceCategory',
          defaultValue: null,
        },
      ],
    },*/
    {
      name: 'slugs',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'slug',
          type: 'string', // TODO: Integrate with builder.io bigcommerce category selection feature
        },
      ],
    },
  ],
})

export default CategoryList
