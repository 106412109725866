import axios from 'axios'
import { useCallback } from 'react'

import { useStore } from '@/stores'
import { recordAddToCart } from '@/lib/google-analytics'
import {
  StorefrontEventType,
  useRecordStorefrontEventMutation,
} from '@/generated/graphql'
import { backendClient } from '@/lib/apollo'

import useCart from './use-cart'

interface IUseAddItemArgs {
  onSuccess?: () => void
  onError?: (error: any) => void
}

const useAddItem = ({ onSuccess, onError }: IUseAddItemArgs) => {
  const [recordStorefrontEvent] = useRecordStorefrontEventMutation({
    client: backendClient,
  })
  const { mutate } = useCart()
  const { isCartOpen, openCart } = useStore()
  const addLineItem = useCallback(
    async (line_item: any /* TODO: type */) => {
      return mutate(async () => {
        try {
          const { data: cart } = await axios.post('/api/cart', {
            line_item,
          })

          onSuccess?.()
          recordAddToCart(line_item)
          recordStorefrontEvent({
            variables: {
              event: StorefrontEventType.AddToCart,
            },
          })

          if (!isCartOpen) {
            openCart()
          }

          return cart
        } catch (e) {
          onError?.(e)
          return null
        }
      })
    },
    [mutate, openCart, isCartOpen, onError, onSuccess, recordStorefrontEvent]
  )

  const addGiftCertificate = useCallback((gift_certificate: any) => {}, [])

  return { addLineItem, addGiftCertificate }
}
export default useAddItem
