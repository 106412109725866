import { gql } from '@apollo/client'
import swatchOptionFragment from './swatch-option'

const multipleChoiceOptionFragment = gql`
  fragment multipleChoiceOption on MultipleChoiceOption {
    values {
      edges {
        node {
          label
          entityId
          ...swatchOption
        }
      }
    }
  }

  ${swatchOptionFragment}
`

export default multipleChoiceOptionFragment
