import { gql } from '@apollo/client'
import productInfoFragment from './product-info'
import productVariantsFragment from './product-variants'

const productConnectionFragment = gql`
  fragment productConnection on ProductConnection {
    pageInfo {
      __typename
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      __typename
      cursor
      node {
        ...productInfo
        ...productVariants
      }
    }
  }

  ${productInfoFragment}
  ${productVariantsFragment}
`

export default productConnectionFragment
