import type { Product } from '@/types/bigcommerce/generated/graphql'

import { FormattedProduct } from '../types'

const useProduct = (productNode: Product): FormattedProduct => {
  const categories =
    productNode.categories?.edges?.map((edge) => edge?.node.name) ?? []

  const isPasswordProtected = categories.indexOf('PASSWORD') > -1

  const images =
    productNode.images.edges?.map((edge) => ({
      url: edge!.node.urlOriginal,
      url320wide: (edge!.node as any).url320wide,
      url640wide: (edge!.node as any).url640wide,
      url960wide: (edge!.node as any).url960wide,
      url1280wide: (edge!.node as any).url1280wide,
      alt: edge?.node.altText,
    })) ?? []

  // Returns empty if the product is not on sale.
  const originalPriceIfOnSale =
    productNode.prices?.basePrice?.value &&
    productNode.prices.basePrice.value > productNode.prices?.price.value
      ? productNode.prices.basePrice.value
      : undefined

  const unlimitedInventory =
    productNode.inventory.isInStock &&
    !productNode.inventory.hasVariantInventory

  const soldOut = !productNode.inventory.isInStock

  const videoMetafield = productNode.metafields?.edges?.find((edge) => {
    return edge?.node?.key === 'video'
  })

  return {
    title: productNode.name.split('•')[0],
    subtitle: productNode.name.split('•')[1],
    path: productNode.path,
    isPasswordProtected,
    originalPriceIfOnSale,
    images,
    soldOut,
    unlimitedInventory,
    videoUrl: videoMetafield?.node?.value,
  }
}
export default useProduct
